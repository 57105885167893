@media screen and (max-width: 451px) {
  .pagination {
    margin-top: -30px !important;
  }
}

@media screen and (min-width: 451px) {
  .pagination {
    margin-top: 0px !important;
  }
}

.badge-primary {
  color: #fff;
  background-color: #047bf8 !important;
}

.badge-secondary {
  color: #fff;
  background-color: #fff !important;
}

.badge-success {
  color: #fff;
  background-color: #24b314 !important;
}

.badge-info {
  color: #292b2c;
  background-color: #5bc0de !important;
}

.badge-warning {
  color: #292b2c;
  background-color: #fbe4a0 !important;
}

.badge-orange {
  color: #fff;
  background-color: orange !important;
}

.badge-purple {
  color: #fff;
  background-color: #6f42c1 !important;
}

.badge-red {
  color: #fff;
  background-color: #c14242 !important;
}

.flex {
  display: flex;
}

.separator {
  margin-top: 40px !important;
  text-align: center !important;
}

.separator span {
  position: relative !important;
  top: -26px !important;
  font-weight: 900;
  width: 200px !important;
  background: white !important;
}

.custom-table-th {
  font-size: 12px !important;
}

.custom-table-th:hover {
  cursor: pointer;
}

.statistic-icon-all {
  background-color: #395cbe !important;
  border-radius: 50%;
  color: white;
  height: 25px;
  width: 25px;
  min-width: 25px;
  min-height: 25px;
  text-align: center;
  display: inline-block;
  padding: 2px;
}

.statistic-icon-primary {
  background-color: #047bf8 !important;
  border-radius: 50%;
  color: white;
  height: 25px;
  width: 25px;
  min-width: 25px;
  min-height: 25px;
  text-align: center;
  display: inline-block;
  padding: 2px;
}

.statistic-icon-success {
  background-color: #24b314 !important;
  border-radius: 50%;
  color: white;
  height: 25px;
  width: 25px;
  min-width: 25px;
  min-height: 25px;
  text-align: center;
  display: inline-block;
  padding: 2px;
}

.statistic-icon-warning {
  background-color: #fbe4a0 !important;
  border-radius: 50%;
  color: white;
  height: 25px;
  width: 25px;
  min-width: 25px;
  min-height: 25px;
  text-align: center;
  display: inline-block;
  padding: 2px;
}

.statistic-icon-orange {
  background-color: orange !important;
  border-radius: 50%;
  color: white;
  height: 25px;
  width: 25px;
  min-width: 25px;
  min-height: 25px;
  text-align: center;
  display: inline-block;
  padding: 2px;
}

.statistic-icon-purple {
  background-color: #6f42c1 !important;
  border-radius: 50%;
  color: white;
  height: 25px;
  width: 25px;
  min-width: 25px;
  min-height: 25px;
  text-align: center;
  display: inline-block;
  padding: 2px;
}
