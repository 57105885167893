.CircularProgressbar {
  width: 150px !important;
  height: 150px !important;
}

.CircularProgressbar .CircularProgressbar-path {
  stroke: #395cbe;
  stroke-linecap: round;
  transition: stroke-dashoffset 0.5s ease 0s;
}

.amountdiv {
  display: flex !important;
  align-items: center !important;
  height: fit-content;
}

#field-wrapper {
  padding-top: 18px !important;
  padding-bottom: 18px !important;
  border: 1px solid #bab5b5;
  border-radius: 5px;
  margin-top: 10px;
}

.fieldContainer1 #field-wrapper {
  padding-top: 18px !important;
  padding-bottom: 18px !important;
  border: 1px solid #bab5b5;
  border-radius: 5px;
  /* margin-top: 10px; */
}

.fieldContainer #field-wrapper {
  padding-top: 23px !important;
  padding-bottom: 23px !important;
  border: 1px solid #bab5b5;
  border-radius: 5px;
  /* margin-top: 10px; */
}

.fieldContainer #field-wrapper img {
  display: none !important;
}

.fieldContainer #field-wrapper label:first-of-type,
.fieldContainer #field-wrapper label:last-of-type {
  display: none !important;
}

.cardNo {
  display: none !important;
  width: 0px;
}

.expiry {
  width: 200px !important;
}

.billingCollap {
  cursor: pointer !important;
  /* border: 1px solid #697386; */
  padding: 6px 10px;
  /* border-radius: 5px !important; */
}

.amountdiv h3 {
  font-weight: 800 !important;
}

.month {
  position: relative;
  top: -7px !important;
}

.per {
  position: relative;
  top: -1px !important;
}

.supText {
  font-size: 16px !important;
  position: relative;
  top: -5px !important;
}

.circleShow {
  display: none !important;
}

.circleHide {
  display: block !important;
}

.powertab {
  flex: none !important;
  max-width: 800px !important;
  min-height: fit-content !important;
}

.paymenttab {
  flex: none !important;
  max-width: 380px !important;
  min-height: fit-content !important;
}

.MuiInputBase-input {
  font-weight: 600 !important;
}

.MuiFormLabel-root {
  color: black !important;
  font-weight: 500 !important;
}

.CardField-postalCode {
  display: none !important;
}

.CircularProgressbar .CircularProgressbar-text {
  /* fill: #395cbe; */
  fill: var(--text-color-two) !important;
  font-size: 11px;
  dominant-baseline: middle;
  text-anchor: middle;
}

.supMonth {
  font-size: 14px !important;
}

@media screen and (max-width: 1439px) {
  .powertab {
    flex: none !important;
    max-width: 750px !important;
  }
  .paymenttab {
    flex: none !important;
    /* max-width: 40% !important; */
    max-width: 380px !important;
  }
  /* .progressCol h1 {
        font-size: 25px !important;
    }
    .supMonth {
        font-size: 18px !important;
    }
    .subscribeCol h2 {
        font-size: 25px !important;
    }
    .CircularProgressbar {
        width: 100px !important;
        height: 100px !important;
    } */
}

@media screen and (max-width: 1390px) {
  .powertab {
    flex: none !important;
    max-width: 720px !important;
  }
  .paymenttab {
    flex: none !important;
    max-width: 350px !important;
  }
  /* .progressCol h1 {
        font-size: 25px !important;
    }
    .supMonth {
        font-size: 18px !important;
    }
    .subscribeCol h2 {
        font-size: 25px !important;
    }
    .CircularProgressbar {
        width: 100px !important;
        height: 100px !important;
    } */
}

@media screen and (max-width: 1318px) {
  .powertab {
    flex: none !important;
    max-width: 700px !important;
  }
  .paymenttab {
    flex: none !important;
    max-width: 300px !important;
  }
  .progressCol h1 {
    font-size: 25px !important;
  }
  .paymenttab h2 {
    font-size: 25px !important;
  }
  .supMonth {
    font-size: 14px !important;
  }
  .subscribeCol h2 {
    font-size: 25px !important;
  }
  .CircularProgressbar {
    width: 150px !important;
    height: 150px !important;
  }
}

@media screen and (max-width: 1318px) {
  .powertab {
    flex: none !important;
    max-width: 650px !important;
  }
  .paymenttab {
    flex: none !important;
    max-width: 300px !important;
  }
  .paymenttab h2 {
    font-size: 25px !important;
  }
  .progressCol h1 {
    font-size: 25px !important;
  }
  .supMonth {
    font-size: 14px !important;
  }
  .subscribeCol h2 {
    font-size: 25px !important;
  }
  .CircularProgressbar {
    width: 150px !important;
    height: 150px !important;
  }
}

@media screen and (max-width: 1203px) {
  .powertab {
    flex: none !important;
    max-width: 600px !important;
  }
  .paymenttab {
    flex: none !important;
    max-width: 300px !important;
  }
  .paymenttab h2 {
    font-size: 25px !important;
  }
  .progressCol h1 {
    font-size: 25px !important;
  }
  .supMonth {
    font-size: 14px !important;
  }
  .subscribeCol h2 {
    font-size: 25px !important;
  }
  .CircularProgressbar {
    width: 150px !important;
    height: 150px !important;
  }
}

@media screen and (max-width: 1203px) {
  .powertab {
    flex: none !important;
    max-width: 600px !important;
  }
  .paymenttab {
    flex: none !important;
    max-width: 300px !important;
  }
  .paymenttab h2 {
    font-size: 25px !important;
  }
  .paymenttab button {
    margin-top: 20px !important;
  }
  .progressCol h1 {
    font-size: 25px !important;
  }
  .supMonth {
    font-size: 14px !important;
  }
  .subscribeCol h2 {
    font-size: 25px !important;
  }
  .CircularProgressbar {
    width: 120px !important;
    height: 120px !important;
  }
}

@media screen and (max-width: 1148px) {
  .powertab {
    flex: none !important;
    max-width: 590px !important;
  }
  .paymenttab {
    flex: none !important;
    max-width: 270px !important;
  }
  .paymenttab h2 {
    font-size: 25px !important;
  }
  .paymenttab button {
    margin-top: 20px !important;
  }
  .progressCol h1 {
    font-size: 25px !important;
  }
  .supMonth {
    font-size: 14px !important;
  }
  .subscribeCol h2 {
    font-size: 25px !important;
  }
  .CircularProgressbar {
    width: 120px !important;
    height: 120px !important;
  }
}

@media screen and (max-width: 1108px) {
  .powertab {
    flex: none !important;
    max-width: 100% !important;
  }
  .paymenttab {
    flex: none !important;
    max-width: 100% !important;
  }
  .paymenttab h2 {
    font-size: 33px !important;
  }
  .paymenttab button {
    margin-top: 35px !important;
  }
  .progressCol h1 {
    font-size: 33px !important;
  }
  .supMonth {
    font-size: 14px !important;
  }
  .subscribeCol h2 {
    font-size: 33px !important;
  }
  .CircularProgressbar {
    width: 150px !important;
    height: 150px !important;
  }
}

@media screen and (max-width: 800px) {
  .progressCol h1 {
    font-size: 33px !important;
  }
  .supMonth {
    font-size: 14px !important;
  }
  .subscribeCol h2 {
    font-size: 33px !important;
  }
  .paymenttab h2 {
    font-size: 33px !important;
  }
  .CircularProgressbar {
    width: 150px !important;
    height: 150px !important;
  }
}

@media screen and (max-width: 674px) {
  .progressCol h1 {
    font-size: 31px !important;
  }
  .supMonth {
    font-size: 14px !important;
  }
  .paymenttab h2 {
    font-size: 31px !important;
  }
  .paymenttab h2 {
    font-size: 31px !important;
  }
  .subscribeCol h2 {
    font-size: 31px !important;
  }
  .CircularProgressbar {
    width: 140px !important;
    height: 140px !important;
  }
}

@media screen and (max-width: 617px) {
  .progressCol h1 {
    font-size: 30px !important;
  }
  .supMonth {
    font-size: 14px !important;
  }
  .paymenttab h2 {
    font-size: 30px !important;
  }
  .subscribeCol h2 {
    font-size: 30px !important;
  }
  .CircularProgressbar {
    width: 130px !important;
    height: 130px !important;
  }
}

@media screen and (max-width: 571px) {
  .progressCol h1 {
    font-size: 28px !important;
  }
  .supMonth {
    font-size: 14px !important;
  }
  .paymenttab h2 {
    font-size: 28px !important;
  }
  .subscribeCol h2 {
    font-size: 28px !important;
  }
  .CircularProgressbar {
    width: 130px !important;
    height: 130px !important;
  }
}

@media screen and (max-width: 548px) {
  .progressCol h1 {
    font-size: 28px !important;
  }
  .supMonth {
    font-size: 14px !important;
  }
  .paymenttab h2 {
    font-size: 28px !important;
  }
  .subscribeCol h2 {
    font-size: 28px !important;
  }
  .widthDouble {
    width: 155% !important;
  }
  .current {
    margin-top: 68px !important;
  }
  .CircularProgressbar {
    width: 150px !important;
    height: 150px !important;
    margin-right: 200px !important;
  }
  .circleShow {
    display: block !important;
  }
  .circleHide {
    display: none !important;
  }
}

@media screen and (max-width: 464px) {
  .progressCol h1 {
    font-size: 28px !important;
  }
  .supMonth {
    font-size: 14px !important;
  }
  .paymenttab h2 {
    font-size: 28px !important;
  }
  .subscribeCol h2 {
    font-size: 28px !important;
  }
  .setHeading {
    width: 260px !important;
    white-space: normal !important;
  }
  .widthDouble {
    width: 165% !important;
  }
  .current {
    margin-top: 55px !important;
  }
  .CircularProgressbar {
    width: 130px !important;
    height: 130px !important;
    margin-right: 200px !important;
  }
  .circleShow {
    display: block !important;
  }
  .circleHide {
    display: none !important;
  }
}

@media screen and (max-width: 425px) {
  .progressCol h1 {
    font-size: 28px !important;
  }
  .supMonth {
    font-size: 14px !important;
  }
  .paymenttab h2 {
    font-size: 28px !important;
  }
  .subscribeCol h2 {
    font-size: 28px !important;
  }
  .widthDouble {
    width: 165% !important;
  }
  .current {
    margin-top: 55px !important;
  }
  .CircularProgressbar {
    width: 130px !important;
    height: 130px !important;
    margin-right: 180px !important;
  }
  .circleShow {
    display: block !important;
  }
  .circleHide {
    display: none !important;
  }
}

@media screen and (max-width: 413px) {
  .progressCol h1 {
    font-size: 25px !important;
  }
  .supMonth {
    font-size: 14px !important;
  }
  .paymenttab h2 {
    font-size: 25px !important;
  }
  .subscribeCol h2 {
    font-size: 25px !important;
  }
  .widthDouble {
    width: 170% !important;
  }
  .current {
    margin-top: 51px !important;
  }
  .CircularProgressbar {
    width: 120px !important;
    height: 120px !important;
    margin-right: 180px !important;
  }
  .circleShow {
    display: block !important;
  }
  .circleHide {
    display: none !important;
  }
}

@media screen and (max-width: 392px) {
  .progressCol h1 {
    font-size: 25px !important;
  }
  .supMonth {
    font-size: 14px !important;
  }
  .paymenttab h2 {
    font-size: 25px !important;
  }
  .subscribeCol h2 {
    font-size: 25px !important;
  }
  .widthDouble {
    width: 160% !important;
  }
  .current {
    margin-top: 51px !important;
  }
  .amountdiv h3 {
    font-size: 22px !important;
  }
  .columnButton {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .per,
  .month {
    font-size: 12px !important;
  }
  .CircularProgressbar {
    width: 150px !important;
    height: 150px !important;
    margin-right: 160px !important;
  }
  .circleShow {
    display: block !important;
  }
  .circleHide {
    display: none !important;
  }
}

@media screen and (max-width: 374px) {
  .progressCol h1 {
    font-size: 25px !important;
  }
  .supMonth {
    font-size: 14px !important;
  }
  .paymenttab h2 {
    font-size: 25px !important;
  }
  .subscribeCol h2 {
    font-size: 25px !important;
  }
  .widthDouble {
    width: 160% !important;
  }
  .current {
    margin-top: 30px !important;
  }
  .CircularProgressbar {
    width: 140px !important;
    height: 140px !important;
    margin-right: 180px !important;
  }
  .circleShow {
    display: block !important;
  }
  .circleHide {
    display: none !important;
  }
}

@media screen and (max-width: 353px) {
  .progressCol h1 {
    font-size: 24px !important;
  }
  .supText {
    font-size: 14px !important;
  }
  .supMonth {
    font-size: 14px !important;
  }
  .paymenttab h2 {
    font-size: 24px !important;
  }
  .subscribeCol h2 {
    font-size: 24px !important;
  }
  .widthDouble {
    width: 155% !important;
  }
  .current {
    margin-top: 30px !important;
  }
  .CircularProgressbar {
    width: 140px !important;
    height: 140px !important;
    margin-right: 180px !important;
  }
  .circleShow {
    display: block !important;
  }
  .circleHide {
    display: none !important;
  }
}

@media screen and (max-width: 345px) {
  .progressCol h1 {
    font-size: 23px !important;
  }
  .supMonth {
    font-size: 14px !important;
  }
  .paymenttab h2 {
    font-size: 23px !important;
  }
  .subscribeCol h2 {
    font-size: 23px !important;
  }
  .widthDouble {
    width: 150% !important;
  }
  .current {
    margin-top: 30px !important;
  }
  .CircularProgressbar {
    width: 140px !important;
    height: 140px !important;
    margin-right: 200px !important;
  }
  .circleShow {
    display: block !important;
  }
  .circleHide {
    display: none !important;
  }
}

@media screen and (max-width: 339px) {
  .progressCol h1 {
    font-size: 23px !important;
  }
  .supMonth {
    font-size: 14px !important;
  }
  .paymenttab h2 {
    font-size: 23px !important;
  }
  .subscribeCol h2 {
    font-size: 23px !important;
  }
  .widthDouble {
    width: 150% !important;
  }
  .current {
    margin-top: 30px !important;
  }
  .CircularProgressbar {
    width: 130px !important;
    height: 130px !important;
    margin-right: 200px !important;
  }
  .circleShow {
    display: block !important;
  }
  .circleHide {
    display: none !important;
  }
}
