@import url(https://fonts.googleapis.com/css?family=Roboto:100);

/*#395CBE background color => Transaparency change only*/

/*https://codepen.io/jidelambo/pen/RaRygY*/

body {
  /*background-image: url(../img/header_bg.jpg?v3);
  z-index: 2;
  background-size: cover;
  background-attachment: fixed;*/
  /*margin-bottom: 80px !important;*/
  padding: 0px !important;
  min-height: auto !important;
}

html {
  position: relative;
  min-height: 100%;
}

.element-box {
  box-shadow: 0px 0px 16px rgb(0 0 0 / 20%);
}

.iradio_square-blue span {
  color: var(text-color) !important;
}

.c-button {
  background-color: #395cbe;
  color: #ffffff;
  border-radius: 8px;
  cursor: pointer;
  border: 1px solid #395cbe;
}

.kyc-container .form-control:not(.no-border-radius) {
  border: 1px solid #ebebeb;
  color: #333333 !important;
}

.walletHover:hover .headerWallet {
  transform: scale(1.1) translate(-6px, -3px);
  transition: 0.2s;
}

.filterStartDate::before {
  content: "Start Date";
  width: 100% !important;
  color: gray !important;
  background: transparent !important;
}

.post-box {
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0px 0px 16px rgb(0 0 0 / 5%);
  padding: 20px;
}

.emd-box {
  height: 90% !important;
  margin-bottom: 20px !important;
  box-shadow: 0px 0px 16px rgb(0 0 0 / 20%);
}

.emd-box:hover {
  box-shadow: 0px 0px 16px rgb(0 0 0 / 40%);
}

.post-box .username {
  margin: 0px 30px;
}

.post-box .description {
  margin: 18px 0px;
}

.post-title {
  line-height: 24px;
}

.post-foot {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.post-box:hover {
  transform: inherit !important;
}

.alert-orange-btn {
  background-color: #ee950e;
  padding: 5px 10px;
  color: white;
  font-size: 14px;
  cursor: pointer;
  border-radius: 7px;
}

.buy-blue-btn {
  background-color: #4d97e7;
  padding: 5px 10px;
  color: #fff;
  font-size: 14px;
  cursor: pointer;
  border-radius: 7px;
  border: 1px solid #0368d2;
}

.buy-blue-btn > a {
  color: #fff !important;
}

.buy-blue-span {
  background-color: #4d97e7;
  padding: 5px 10px;
  color: #fff !important;
  font-size: 14px;
  cursor: pointer;
}

.buy-blue-span > a {
  color: #fff !important;
  font-size: 16px;
}

.filterEndDate::before {
  content: "End Date";
  width: 100% !important;
  color: gray !important;
  background: transparent !important;
}

.kyc-button-group label span {
  color: var(--text-color) !important;
}

.hoverUp:hover {
  background: var(--background-color-seven) !important;
  border-radius: 10px;
  transform: translateY(-5px);
  transition: 0.2s !important;
}

.resource-card-outer-container {
  padding: 10px !important;
  width: 33% !important;
}

.resource-card-container {
  box-shadow: 1px 1px 16px rgb(2 1 1 / 20%);
  border: 1px solid #e9e9e9;
  border-radius: 10px !important;
  padding: 20px !important;
  transition: 0.2s;
  width: 100% !important;
}

.resource-card-container:hover {
  transform: translateY(-10px);
  box-shadow: 1px 1px 16px rgb(2 1 1 / 50%);
  transition: 0.2s;
}

.resource-card-img {
  border-radius: 10px !important;
  margin-bottom: 20px !important;
  transition: 0.2s;
  cursor: pointer !important;
}

.resource-card-img-container {
  width: 100% !important;
  border-radius: 10px !important;
  overflow: hidden !important;
}

.lazyWrapper {
  width: 100% !important;
}

.resource-card-img:hover {
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.09);
  transform: translateY(-5px);
  transition: 0.2s;
}

.rightHeaderIcon img {
  width: 40px !important;
  height: 40px !important;
  margin-right: 10px !important;
}

.calculateContainer {
  display: flex !important;
  justify-content: flex-start !important;
  padding: 15px 12px !important;
}

.calculateContainer button:first-of-type {
  margin-right: 10px !important;
}

.pdf-content {
  max-height: 550px;
  overflow-y: auto !important;
  padding: 10px;
  padding-right: 20px;
}

.pdf-content p {
  font-size: 16px !important;
}

.pdf-content h6 {
  font-size: 16px !important;
}

.pdf-content th,
.pdf-content td,
.pdf-content li {
  font-size: 16px !important;
}

.span-border {
  font-weight: bold !important;
}

.heading-border {
  border-bottom: 1px solid black;
}

.agreementQR {
  display: flex !important;
}

.d-onboarding-content p {
  font-size: 10px !important;
  margin-right: 40px !important;
}

.d-onboarding-content h6 {
  font-size: 12px !important;
}

.d-onboarding-content td,
.d-onboarding-content th,
.d-onboarding-content li {
  font-size: 10px !important;
}

.kyc-container ::placeholder {
  color: white;
  opacity: 0.5;
}

.clickWrap-table-pdf th {
  background-color: gainsboro;
}

.clickWrap-table-pdf th:not(:last-child),
.clickWrap-table-pdf td:not(:last-child) {
  border-right: 1px solid black;
}

.clickWrap-table-pdf {
  border-left: 1px solid black;
  border-right: 1px solid black;
  border-bottom: 1px solid black;
}

.clickWrap-table-pdf th,
.clickWrap-table-pdf td {
  border-top: 1px solid black;
  padding: 5px;
}

.kyc-container select {
  color: white !important;
}

.kyc-container .dropzone {
  background-color: #395cbe !important;
  border: 2px dashed white !important;
}

.bg-full-image {
  width: 100%;
  height: 100%;
  min-width: 100%;
  min-height: 100%;
  position: relative;
}

.kyc-header {
  font-size: 20px;
  line-height: 24px;
  color: #395cbe;
  margin: 30px 0;
}

.filtered {
  filter: sepia(100%) hue-rotate(190deg) saturate(500%);
}

.bg-full-image:before {
  background-image: url(../img/header_bg.jpg?v3) !important;
  background-size: cover !important;
  background-attachment: fixed;
  content: "";
  display: block;
  position: fixed !important;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100vh !important;
}

.collapLi {
  display: flex !important;
  margin-left: 10px !important;
  padding: 10px 0px !important;
}

.rowInput {
  display: flex !important;
  justify-content: flex-start !important;
  align-items: center !important;
  white-space: nowrap;
}

.card-horizontal-padding {
  padding: 0 24px;
}

.newonboarding-content {
  padding: 0px !important;
}

footer {
  position: absolute;
  bottom: -15px;
  width: 100%;
  height: 30px;
  line-height: 48px;
}

.coinInfoBox {
  width: 200px !important;
  height: 200px !important;
}

.QrRow {
  display: flex !important;
  justify-content: center !important;
}

.logowithText {
  display: flex !important;
  align-items: center;
  width: 273px !important;
  margin-left: -10px;
}

.base-footer .footer {
  padding: 10px;
  position: relative !important;
  margin-top: 0 !important;
}

.custom-wrapper .footer {
  position: relative;
}

.color-white {
  color: white !important;
}

.confirmationBox {
  display: flex !important;
  justify-content: space-between !important;
}

.confirmationColumn1 {
  display: "flex";
  align-items: center !important;
}

.background-white {
  background-color: white !important;
}

.clickWrap-table tr th {
  border: 1px solid black;
  padding: 5px !important;
  background-color: gainsboro;
}

.clickWrap-table tr td {
  border: 1px solid black;
  padding: 5px !important;
}

.bg-overlay {
  background: rgba(63, 81, 181, 0.5) !important;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 0;
}

.collapLi {
  display: flex !important;
  margin-left: 10px !important;
  padding: 10px 0px !important;
}

.Collapsible__contentInner .all-wrapper.with-pattern {
  background-image: none !important;
  min-height: 65vh !important;
}

.main-content-container {
  padding-top: 60px;
}

.kyc-status-button {
  padding: 0px 24px !important;
  background: #ffffff;
  border: 1px solid #395cbe;
  border-radius: 8px;
  height: 32px;
  color: #395cbe;
}

.custom-content-container {
  margin-top: 30px;
}

.terms-container p {
  font-size: 14px;
}

.auth-box-w.wider {
  max-width: 750px !important;
  border-radius: 50px !important;
}

.auth-box-w.terms-wider {
  max-width: 95% !important;
}

.auth-box-w.login-box {
  border-radius: 50px !important;
}

.auth-box-w .logo-w {
  padding: 20px 0 0 0 !important;
}

.modal-button {
  height: 32px;
  padding: 0px 24px;
  border-radius: 8px;
}

.kyc-content {
  margin-top: 4rem;
  height: 60vh;
}

.cancel-button {
  background-color: #ffffff;
  color: #395cbe;
  border: 1px solid #395cbe;
}

.update-button {
  background-color: #395cbe;
  color: #ffffff;
  border: 1px solid #395cbe;
  margin-bottom: 8px;
}

#loading {
  display: inline-block;
  width: 30px;
  height: 30px;
  border: 3px solid #047bf8;
  border-radius: 50%;
  border-top-color: #fff;
  animation: spin 1s ease-in-out infinite;
  animation: spin 1s ease-in-out infinite;
  -webkit-animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}

.rotate {
  animation: spinLogo 5s linear infinite;
}

@keyframes spinLogo {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.table-striped thead tr th,
.table-lightfont thead tr th {
  white-space: nowrap !important;
}

.table-striped tbody tr td,
.table-lightfont tbody tr td {
  white-space: nowrap !important;
}

.auth-box-w {
  background-color: #395cbe !important;
  opacity: 0.95 !important;
}

.custom-container {
  background: #ffffff;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  margin: 0 auto;
}

.terms-header {
  text-align: center;
  font-size: 20px;
  color: #395cbe;
  margin-bottom: 0;
  margin-top: 1rem;
}

.terms-topbar {
  background-color: #395cbe;
}

.terms_conditions_wrapper_container {
  background-color: #fafafa;
}

.custom-form .form-control {
  padding: 18px !important;
  border-radius: 30px !important;
  border: none !important;
  color: grey !important;
  font-size: 16px !important;
}

.custom-form ::placeholder {
  color: grey !important;
  opacity: 1;
}

.default-btn {
  color: #ffffff;
  background-color: #0368d2;
  border-color: #0362c6;
}

.default-btn:hover {
  color: #ffffff !important;
}

.custom-submit-button {
  width: 100% !important;
  border-radius: 50px !important;
  padding: 20px !important;
  background: linear-gradient(134deg, #56b8e4 0%, #6b8ccf 100%) !important;
  border: 1px solid white !important;
  color: #ffffff;
}

.auth-box-w .buttons-w {
  border-top: none !important;
}

.p-inherit {
  padding: inherit !important;
}

.info-bottom-link {
  color: white !important;
  float: right !important;
  padding-right: 15px !important;
  font-size: 18px !important;
}

.logout-link {
  color: white !important;
  float: right !important;
  padding: 5px 20px !important;
  font-size: 18px !important;
  border: 2px solid white;
  border-radius: 50px;
}

.terms-block {
  font-size: 18px !important;
  color: white !important;
}

.auth-box-w form {
  padding: 20px 40px 40px 40px !important;
}

.type-selector {
  border: 1px solid white !important;
  border-radius: 50px !important;
  padding: 15px !important;
  color: white !important;
}

.copyright-text {
  position: relative;
  font-size: 14px !important;
  color: var(--text-color);
}

.type-selector label {
  padding-right: 15px !important;
  padding-left: 0.5rem !important;
  font-size: 16px;
}

.type-selector span {
  padding-left: 5px !important;
}

.gender-container label {
  padding-right: 10px !important;
  font-size: 16px;
}

.gender-container span {
  padding-left: 5px !important;
}

/* Styles for wrapping the search box */

.main {
  width: 50%;
  margin: 50px auto;
}

.custom-form .input-group-text {
  position: absolute !important;
  z-index: 999 !important;
  top: 15% !important;
  left: 0% !important;
  padding: 14px !important;
  border-radius: 50px !important;
  margin-left: 5px !important;
  background: #3a5ebe !important;
  color: white !important;
}

.custom-form .input-group input {
  text-indent: 2.75rem !important;
}

span.error {
  color: #ccd9e8 !important;
  font-size: 15px !important;
}

.login-container .auth-box-w .buttons-w {
  padding-top: 2rem !important;
}

.custom-form .form-control {
  width: 100% !important;
}

.disabled {
  opacity: 0.9 !important;
  cursor: not-allowed !important;
}

.custom-top-bar {
  z-index: 1100 !important;
  background: var(--background-color-one);
}

.custom-bg-overlay {
  z-index: 0 !important;
}

.top-bar:not(.admin-top-bar) .top-menu-controls {
  padding: 25px 0 !important;
}

.customLogo {
  margin-left: -7px !important;
}

.newmodal-content {
  margin-bottom: 0px !important;
}

.custom-username {
  color: white !important;
  font-size: 16px;
}

.custom-wrapper {
  height: 100% !important;
  min-height: 100vh !important;
}

.custom-background-color {
  background-color: #5e9ed8 !important;
  height: auto !important;
  min-height: 100vh !important;
}

.user-dashboard-header-bg-color {
  background-color: #5e9ed8 !important;
  height: auto !important;
  min-height: auto !important;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%) !important;
}

.custom-element-box {
  background: var(--background-color-three) !important;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 20%);
  border-radius: 8px;
}

.custom-element-box .element-box {
  background: var(--background-color-three) !important;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 20%);
  border-radius: 8px;
  margin-bottom: 0 !important;
}

#keyLeft {
  margin-left: -15px !important;
}

#keyLeft i {
  cursor: pointer !important;
  color: #395cbe;
}

.setDeclogo {
  position: relative !important;
}

.standardColorIcon {
  color: var(--text-color-two) !important;
}

.custom-element-box .form-header {
  color: var(--text-color) !important;
  font-size: 20px;
}

.kyc-container .form-control:not(.no-border-radius) {
  background-color: none !important;
}

.kyc-container ::placeholder {
  color: white;
  opacity: 0.5;
}

.permian-button {
  border-radius: 8px;
  border: none;
  margin: 9px 3px !important;
  padding: 10px 20px !important;
  width: 6rem;
  padding: -4px 52px;
  background-color: #395cbe !important;
}

.permian-button:focus {
  box-shadow: 0 0 0 0.2rem rgba(4, 123, 248, 0.5) !important;
}

.termsHeading {
  margin-left: 10px !important;
}

.kyc-container select {
  color: white !important;
}

.kyc-container .dropzone {
  background-color: #395cbe !important;
  border: 2px dashed white !important;
}

.resource-continaer {
  background-color: var(--background-color-nine) !important;
}

.resource-continaer.dropzoneIdContainer {
  padding: 20px !important;
}

.country-select2 .cntry__control {
  background-color: transparent !important;
  color: var(--text-color-two) !important;
}

.country-select4 {
  background-color: transparent !important;
  z-index: 10 !important;
}

.country-select3 {
  background-color: transparent !important;
  z-index: 10 !important;
}

.contry__control {
  padding: 3px !important;
}

.country-select2 ::placeholder,
.cntry__single-value,
.cntry__placeholder,
.cntry__indicators svg {
  color: var(--text-color-two) !important;
}

.icon-pt-4 {
  position: relative;
  top: -4px;
}

.cntry__menu {
  background-color: #5e9ed8 !important;
  color: white !important;
}

.cntry__option--is-focused {
  z-index: 100 !important;
  background-color: #395cbe !important;
}

.custom-content-container {
  overflow: hidden !important;
}

.dob-container .react-datepicker-wrapper,
.dob-container .react-datepicker__input-container {
  display: inline !important;
}

.kyc-agree-container {
  margin-top: 25px !important;
  padding-left: 0px !important;
}

.kyc-agree-container label {
  font-size: 16px !important;
}

.progress-steps-container .rc-steps-item-finish .rc-steps-item-tail:after {
  background-color: white;
}

.dropzoneIdContainer {
  padding: 10px 0 !important;
}

.rdtPicker {
  background-color: #5e9ed8 !important;
}

.rdtPicker td.rdtDay:hover,
.rdtPicker td.rdtHour:hover,
.rdtPicker td.rdtMinute:hover,
.rdtPicker td.rdtSecond:hover,
.rdtPicker .rdtTimeToggle:hover {
  background: #5e9ed8 !important;
}

.rdtPicker td.rdtActive,
.rdtPicker td.rdtActive:hover {
  background-color: #5e9ed8 !important;
}

.rdtPicker thead tr:first-child th:hover {
  background: #5e9ed8 !important;
}

.CircularProgressbar .CircularProgressbar-path {
  stroke: #395cbe;
  stroke-linecap: round;
  transition: stroke-dashoffset 0.5s ease 0s;
}

td.rdtMonth:hover,
td.rdtYear:hover {
  background: #5e9ed8 !important;
}

.cntry__input {
  color: white !important;
}

.kyc-status-container {
  color: white !important;
}

.progress-icon-container {
  padding: 12px !important;
  background-color: #395cbe !important;
  border-radius: 50%;
}

.kycstatus-head {
  font-weight: 200 !important;
  font-size: 55px !important;
}

body.menu-position-side .content-w {
  border-radius: 0 !important;
}

.required-sign {
  color: red !important;
}

.insiderRadio {
  display: flex !important;
  align-items: center !important;
}

.insiderRadio label {
  margin-left: 10px !important;
}

.styles_overlay__CLSq- {
  background: rgba(0, 0, 0, 0.5) !important;
}

.admin-container,
.admin-container .menu-w,
.user-dashboard-container,
.user-dashboard-container .menu-w {
  height: 100% !important;
  min-height: 100vh !important;
}

.progress-icon-container.bg-red {
  background-color: red !important;
}

.kyc-status-button {
  background-color: #395cbe !important;
  border-radius: 50px !important;
  padding: 15px 20px !important;
}

.text-bold {
  font-weight: bolder !important;
}

.buy-xpr-container {
  font-size: 16px !important;
  background: var(--background-color-two);
}

.balance-summary-container {
  background: var(--background-color-three);
  margin-right: 36px;
  margin-top: 70px;
  box-shadow: "1px 1px 11px rgb(116, 116, 116)";
  z-index: "100";
  color: var(--text-color);
  width: 450px !important;
}

.dark-blue-theme-color {
  border-radius: 8px;
  background: var(--background-color-three);
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 22%);
  color: var(--text-color);
}

.dark-blue-font-color {
  color: #333333 !important;
}

.buy-xpr-header-block {
  font-size: 18px !important;
}

.listing-reserves-input {
  padding-left: 12px !important;
  height: 30px !important;
  border-radius: 50px 0px 0px 50px;
  opacity: 1 !important;
  border: white !important;
  font-size: 16px;
  font-weight: 500;
  padding-right: 10px;
}

.buy-xpr-currencey-select {
  height: 35px !important;
  border-radius: 0px 8px 8px 0px;
  background-color: #ffffff;
  border: 1px solid #ebebeb !important;
  padding: 0 2px !important;
  font-size: 16px;
}

.buy-xpr-currencey-select:focus {
  outline: none;
}

.element-box {
  padding: 1rem 1.5rem;
}

.buy-xpr-currencey-select option {
  background-color: white !important;
  border: 1px solid #ebebeb;
}

.buy-xpr-currencey-select,
.buy-xpr-currencey-select option {
  color: #395cbe !important;
}

.tokens-xpr-input {
  width: 20% !important;
  padding-left: 20px !important;
  height: 30px !important;
  border-radius: 50px 0px 0px 50px;
  opacity: 1 !important;
  border: white !important;
  font-size: 16px;
  font-weight: 500;
  text-align: right;
  padding-right: 10px;
}

.token-symbol-input {
  width: 7% !important;
  background-color: #59adde !important;
  border: white !important;
  opacity: 1 !important;
  border-radius: 0px 50px 50px 0px;
  height: 30px !important;
  color: white !important;
}

.highligher {
  font-size: 16px;
  font-weight: 600;
}

.receive-text {
  font-size: 16px;
}

.tab button {
  background-color: inherit;
  border: 0.5px solid #ebebeb;
  outline: none;
  cursor: pointer;
  padding: 8px 12px 8px 12px;
  transition: 0.3s;
  font-size: 14px;
  color: #395cbe;
}

.tab-group .download-btn {
  background-color: #395cbe;
  border-radius: 8px;
  color: #ffffff;
  padding: 0px 8px;
  cursor: pointer;
  font-size: 14px;
  border: 0.5px solid #395cbe;
  outline: none;
  margin-left: 10px;
}

.kyc-alert-container {
  display: flex;
}

.dropdown-submenu {
  position: relative;
}

.dropdown-submenu .dropdown-menu {
  top: 0;
  left: 100%;
  margin-top: -1px;
}

.badge1 {
  border-radius: 8px;
  display: inline-block;
  padding: 0.25em 0.4em;
  font-weight: 500;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
}

.hour-btn {
  border-radius: 8px 0px 0px 8px;
  color: var(--text-color-two) !important;
}

.day-btn {
  border-radius: 0px 8px 8px 0px;
  color: var(--text-color-two) !important;
}

.tab button:hover {
  background-color: #ddd;
}

.tab button.active {
  background-color: #ccc;
}

.dot {
  height: 8px;
  width: 8px;
  border-radius: 50%;
  display: inline-block;
}

.green {
  background-color: green;
}

.yellow {
  background-color: yellow;
}

.eth-contribution-input {
  width: 55% !important;
  border-radius: 50px !important;
  height: 30px !important;
  padding-left: 10px !important;
  border: white !important;
  opacity: 1;
  background-image: linear-gradient(
    134deg,
    #59adde 0%,
    #57ccec 100%
  ) !important;
  padding-left: 10px !important;
}

.buy-xpr-container h3,
.buy-xpr-container h6 {
  font-weight: normal !important;
}

.buy-xpr-buttons {
  border-radius: 50px !important;
  margin-left: 20px !important;
  background-color: transparent;
  padding: 5px 15px !important;
  font-size: 16px;
  border: 1px solid white !important;
  font-weight: 200 !important;
}

.qrcode-image-container img {
  margin-top: 15% !important;
}

.filepond--root {
  border: 2px dashed #ebebeb !important;
}

.buy-online-payment-buttons {
  width: 25%;
  background-color: transparent;
  border-radius: 50px !important;
  border: 1px solid white !important;
}

.myaccount-whitelist-container {
  display: flex;
}

.react-confirm-alert-button-group {
  justify-content: flex-end !important;
}

.react-confirm-alert-button-group button:first-child {
  background-color: #ffffff;
  color: #395cbe;
  border-radius: 8px;
  border: 1px solid #395cbe;
}

.react-confirm-alert-button-group button:last-child {
  background-color: #395cbe;
  color: #ffffff;
  border-radius: 8px;
  border: 1px solid #395cbe;
}

.whitelist-select {
  height: 32px !important;
  border-radius: 8px;
  background-color: #ffffff;
  border: 1px solid #395cbe !important;
  font-size: 14px !important;
  margin-left: 12px;
  color: #395cbe;
}

.whitelist-select:focus {
  outline: none;
}

.menu-w {
  width: 220px;
}

.card-header {
  display: flex;
  justify-content: space-between;
}

.onboarding-content h4 {
  margin-right: 20px !important;
}

.setKey {
  word-break: break-all !important;
}

.myaccount_card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.admin-error-msg,
.validation-red-error {
  color: red !important;
}

.text-default-color {
  color: var(--text-color);
}

.text-xl {
  font-size: 20px;
}

.text-large {
  font-size: 16px;
}

.listing-modal-container {
  padding: 0 !important;
}

.min-width-50 {
  min-width: 45% !important;
}

.signuppage-info-bottom-link {
  color: white !important;
  font-size: 18px !important;
}

.text-align-end {
  text-align: end;
}

.signin-info-bottom-link {
  text-align: end !important;
  color: white !important;
  font-size: 18px !important;
}

.listing-modal-container
  .onboarding-modal
  .onboarding-content
  .onboarding-text {
  color: black !important;
}

.required-field {
  color: red;
}

.my-account-container .my-account-header-block h5 {
  font-weight: 400 !important;
}

.account-button {
  padding: 5px 10px !important;
  color: #395cbe;
  border: 1px solid #395cbe;
  border-radius: 8px;
  background-color: #ffffff !important;
}

.account-button:focus {
  box-shadow: 0 0 0 0.2rem rgba(4, 123, 248, 0.5) !important;
}

.my-account-button {
  margin: 10px 0 !important;
  padding: 10px 20px !important;
}

.my-account-container .kyc-success-icon-container {
  padding: 12px !important;
  background-color: white !important;
  color: #395cbe !important;
  border-radius: 50%;
}

.text-style {
  color: var(--text-color);
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
}

.my-account-container .verified-text {
  font-size: 1.3rem !important;
}

.my-account-container .pull-right span {
  color: #333333;
  font-size: 14px !important;
}

.two-fa-code-input {
  border: 1px solid #ebebeb !important;
  color: #333333;
  border: none;
  border-radius: 8px;
}

.my-account-container h5 span {
  font-size: 1.2rem !important;
}

.buy-currency-box-display {
  padding: 2.5rem !important;
  border-radius: 50px !important;
  background-color: grey !important;
}

.buy-copy-button {
  padding: 15px;
  border-radius: 50px;
  background-color: #395cbe !important;
  border-color: white !important;
  margin-left: 15px !important;
}

.modal-contribution-address {
  font-size: 18px !important;
  font-weight: 600 !important;
}

.my-account-header-block {
  padding: 18px 24px;
  min-height: 240px !important;
}

.two-fa-code-input {
  width: 250px !important;
  display: inline-block !important;
  margin: 0 15px !important;
}

.two-factor-heading-text {
  font-size: 16px !important;
}

.receiving-eth-address {
  border-radius: 8px !important;
  padding: 7px !important;
  border: 1px solid #ebebeb !important;
  color: #395cbe !important;
  font-size: 14px !important;
}

.dashboard-card-title {
  padding: 18px 12px 10px;
}

.kyc-file-upload-container {
  width: 100% !important;
  display: table !important;
  cursor: pointer !important;
  height: 8rem !important;
  border: 1px white dashed;
}

.kyc-multifile-upload-container {
  width: 100% !important;
  display: table !important;
  cursor: pointer !important;
  height: auto !important;
  border: 1px white dashed;
  min-height: 8rem !important;
}

.upload-text-display {
  display: table-cell !important;
  vertical-align: middle !important;
}

.width-auto {
  width: auto !important;
  display: inline !important;
}

.display-inline {
  display: inline !important;
}

.onboarding-modal .onboarding-slide .onboarding-content {
  padding: 24px !important;
}

.newonboarding-modal .newonboarding-slide .newonboarding-content {
  padding: 24px 0px !important;
  box-sizing: border-box !important;
}

.progressbar {
  counter-reset: step !important;
}

.progressbar li {
  list-style: none !important;
  display: inline-block !important;
  width: 20% !important;
  position: relative !important;
  text-align: center !important;
  font-size: 14px;
}

.progressbar li:before {
  content: counter(step) !important;
  counter-increment: step !important;
  width: 24px !important;
  height: 24px !important;
  border: 1px solid #395cbe !important;
  border-radius: 100% !important;
  display: block !important;
  text-align: center !important;
  margin: 0 auto 5px auto !important;
  background-color: #fff !important;
  color: #395cbe;
  line-height: 22px;
}

.progressbar li:after {
  content: "" !important;
  position: absolute !important;
  width: 100% !important;
  height: 1px !important;
  background-color: #395cbe !important;
  top: 10px !important;
  left: -50% !important;
  z-index: 0 !important;
}

.progressbar li:first-child:after {
  content: none !important;
}

.progressbar li.active {
  color: var(--text-color) !important;
}

.progressbar li.active:before {
  border-color: white !important;
  color: white !important;
  background-color: #395cbe !important;
}

.progressbar li.active + li:after {
  background-color: #395cbe !important;
  z-index: 1 !important;
}

.filter-element-wrapper {
  padding-bottom: 0rem !important;
}

.filter-element-wrapper .form-control {
  width: 20rem !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

.dashboard-header {
  font-size: 15px !important;
}

.color-red {
  color: red !important;
}

.dashboard-statistics-container:not(:last-child),
.user-statistics-container {
  border-right: 1px solid white !important;
}

.barrel-display {
  display: inline !important;
  padding: 3px 10px !important;
  border-radius: 50px !important;
  background: white !important;
  color: #395cbe !important;
}

.bg-white {
  background: white !important;
}

.form-check {
  padding-left: 0.25rem !important;
}

.dashboard-my-investment-list td {
  padding: 0.25rem !important;
}

.el-tablo .value {
  font-size: 1rem !important;
}

.dashboard-my-investment-list.table thead th {
  border-bottom: none !important;
}

.text-underline {
  text-decoration: underline !important;
}

.custom-tooltip {
  display: inline;
  position: relative;
}

.custom-tooltip-coin {
  display: initial !important;
  position: relative !important;
}

.custom-tooltip-coin4 {
  display: initial !important;
  position: relative !important;
}

.custom-tooltip-coin1 {
  display: initial !important;
  position: relative !important;
}

.custom-tooltip-coin2 {
  display: initial !important;
  position: relative !important;
}

.return-rate-tooltip {
  position: absolute;
  bottom: 10px;
  left: 30px;
  width: 200px;
  font-size: 14px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px,
    rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
  padding: 10px;
  margin-top: 10px;
  background-color: var(--background-color-four);
  z-index: 98;
  visibility: hidden;
  border-radius: 10px;
}

.payback-tooltip {
  position: absolute;
  width: 200px;
  font-size: 14px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px,
    rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
  padding: 10px;
  margin-top: 10px;
  background-color: var(--background-color-four);
  z-index: 98;
  visibility: hidden;
  border-radius: 10px;
}

.earningTooltip {
  position: absolute !important;
  width: 400px !important;
  font-size: 14px !important;
  box-shadow: 1px 1px 2px rgb(116, 116, 116);
  padding: 10px;
  margin-top: -5px !important;
  background-color: var(--background-color-four);
  z-index: 98;
  visibility: hidden;
  border-radius: 10px;
}

.earningTooltip1 {
  position: absolute !important;
  width: 314px !important;
  font-size: 14px !important;
  box-shadow: 1px 1px 2px rgb(116, 116, 116);
  padding: 10px;
  margin-top: -5px !important;
  white-space: break-spaces;
  background-color: var(--background-color-four);
  z-index: 98;
  visibility: hidden;
  border-radius: 10px;
}

.earningTooltip2 {
  position: absolute !important;
  width: 230px !important;
  font-size: 14px !important;
  box-shadow: 1px 1px 2px rgb(116, 116, 116);
  padding: 10px;
  margin-top: -5px !important;
  background-color: var(--background-color-four);
  z-index: 98;
  visibility: hidden;
  border-radius: 10px;
}

.subInfo {
  word-wrap: break-word !important;
  width: 100% !important;
  white-space: break-spaces;
  z-index: 98;
}

.walletTooltip {
  position: absolute !important;
  width: 400px !important;
  font-size: 14px !important;
  box-shadow: 1px 1px 2px rgb(116, 116, 116);
  padding: 10px;
  margin-top: -5px !important;
  background-color: #efefef;
  z-index: 98;
  visibility: hidden;
  border-radius: 10px;
}

.walletTooltip1 {
  position: absolute !important;
  width: 400px !important;
  font-size: 14px !important;
  box-shadow: 1px 1px 2px rgb(116, 116, 116);
  padding: 10px;
  margin-top: -5px !important;
  background-color: var(--background-color-four);
  z-index: 98;
  visibility: hidden;
  border-radius: 10px;
}

.stripeTooltip {
  position: absolute !important;
  width: 118px !important;
  font-size: 14px !important;
  box-shadow: 1px 1px 2px rgb(116, 116, 116);
  padding: 10px;
  top: -45px;
  background-color: #efefef;
  z-index: 98;
  visibility: hidden;
  border-radius: 10px;
}

.btcTooltip {
  position: absolute !important;
  width: 110px !important;
  font-size: 14px !important;
  box-shadow: 1px 1px 2px rgb(116, 116, 116);
  padding: 10px;
  top: -40px;
  background-color: #efefef;
  z-index: 98;
  visibility: hidden;
  border-radius: 10px;
}

.wireTooltip {
  position: absolute !important;
  width: 108px !important;
  font-size: 14px !important;
  box-shadow: 1px 1px 2px rgb(116, 116, 116);
  padding: 10px;
  top: -45px;
  background-color: #efefef;
  z-index: 98;
  visibility: hidden;
  border-radius: 10px;
}

.cryptoTooltip {
  position: absolute !important;
  font-size: 14px !important;
  box-shadow: 1px 1px 2px rgb(116, 116, 116);
  padding: 10px;
  margin-top: -5px !important;
  background-color: #efefef;
  z-index: 98;
  visibility: hidden;
  border-radius: 10px;
}

.custom-tooltip:hover:after {
  background: #efefef;
  border-radius: 5px;
  box-shadow: 1px 1px 2px rgb(116, 116, 116);
  bottom: 26px;
  color: black;
  content: attr(tooltip-title);
  left: 20%;
  padding: 0.25rem 0.5rem;
  position: absolute;
  z-index: 98;
}

.custom-tooltip:hover:before {
  border: solid;
  border-color: #333 transparent;
  border-width: 6px 6px 0 6px;
  bottom: 20px;
  content: "";
  left: 50%;
  position: absolute;
  z-index: 99;
}

.dollarImg {
  width: 25px !important;
  height: 25px !important;
  cursor: pointer !important;
  overflow-y: none;
}

.balanceInfo1 {
  font-size: 16px !important;
  display: flex !important;
  align-items: center !important;
  width: 140px;
  justify-content: space-between !important;
}

.icon-info {
  font-size: 14px !important;
  position: relative;
  top: -3px;
  left: -1px;
}

.custom-tooltip-coin:hover > .payback-tooltip {
  visibility: visible !important;
}

.custom-tooltip-coin:hover > .return-rate-tooltip {
  visibility: visible !important;
}

.custom-tooltip-coin:hover > .earningTooltip {
  visibility: visible !important;
}

.custom-tooltip-coin4:hover > .earningTooltip {
  visibility: visible !important;
}

.custom-tooltip-coin2:hover > .earningTooltip1 {
  visibility: visible;
}

.custom-tooltip-coin2:hover > .earningTooltip2 {
  visibility: visible;
}

.custom-tooltip-coin2:hover > .stripeTooltip {
  visibility: visible;
}

.custom-tooltip-coin2:hover > .btcTooltip {
  visibility: visible;
}

.custom-tooltip-coin2:hover > .wireTooltip {
  visibility: visible;
}

.custom-tooltip-coin1:hover > .walletTooltip {
  visibility: visible;
}

.custom-tooltip-coin1:hover > .walletTooltip1 {
  visibility: visible;
}

.custom-tooltip-coin2:hover > .stripeTooltip {
  visibility: visible;
}

.custom-tooltip-coin2:hover > .btcTooltip {
  visibility: visible;
}

.custom-tooltip-coin2:hover > .wireTooltip {
  visibility: visible;
}

.custom-tooltip-coin:hover:before {
  border: solid;
  border-color: #333 transparent;
  border-width: 6px 6px 0 6px;
  top: 25px;
  content: "";
  left: 30%;
  position: absolute;
  z-index: 99;
}

.custom-tooltip-coin4:hover:before {
  position: absolute;
  z-index: 99;
}

.custom-tooltip-coin1:hover:before {
  border: solid;
  border-color: #333 transparent;
  border-width: 6px 6px 0 6px;
  top: 20px;
  content: "";
  left: 20%;
  position: absolute;
  z-index: 99;
}

.custom-tooltip.tooltip-right:hover:after {
  right: 20% !important;
  left: inherit !important;
}

.custom-tooltip-coin.tooltip-right:hover:after {
  right: 20% !important;
  left: inherit !important;
}

.custom-tooltip-coin4.tooltip-right:hover:after {
  right: 20% !important;
  left: inherit !important;
}

.custom-tooltip-coin1.tooltip-right:hover:after {
  right: 20% !important;
  left: inherit !important;
}

.custom-tooltip-coin2.tooltip-right:hover:after {
  right: 20% !important;
  left: inherit !important;
}

.custom-tooltip.tooltip-right:hover:before {
  right: 50% !important;
  left: inherit !important;
}

.custom-tooltip-coin.tooltip-right:hover:before {
  right: 50% !important;
  left: inherit !important;
}

.custom-tooltip-coin4.tooltip-right:hover:before {
  right: 50% !important;
  left: inherit !important;
}

.custom-tooltip-coin2.tooltip-right:hover:before {
  right: 50% !important;
  left: inherit !important;
}

.display-inherit {
  display: inherit !important;
}

.white-background {
  background-color: white !important;
}

.xpr-chart-header {
  color: #395cbe !important;
  font-size: 16px !important;
}

.qrcode-image-container img {
  padding-bottom: 20px !important;
}

.document-type-header {
  color: #395cbe !important;
}

.filepond--root {
  min-height: 8rem !important;
  border: 2px dotted white !important;
}

.filepond--panel-root {
  background-color: transparent !important;
}

.progressreportadd .filepond--drop-label {
  color: var(--text-color-two) !important;
  font-size: 16px !important;
  font-weight: bold !important;
}

.filepond--file-action-button.filepond--action-process-item {
  display: none !important;
}

.split-radio-container label {
  min-width: 35% !important;
}

.understanding2-split-radio-container label {
  min-width: 33% !important;
}

.wealth-split-radio-container label {
  min-width: 25% !important;
  margin-top: 5px !important;
}

.understanding-split-radio-container label {
  min-width: 20% !important;
  margin-top: 5px !important;
}

.radio-half-width-container label {
  min-width: 50% !important;
  margin-top: 5px !important;
}

.financial-products-invested label {
  min-width: 25% !important;
  margin-top: 5px !important;
}

.financial-information-textarea {
  background-color: transparent !important;
  color: white !important;
  resize: none !important;
}

.signature-pad-container {
  border: 2px dotted white !important;
}

.clear-sign-action {
  font-size: 0.8rem !important;
}

.terms-container {
  padding: 24px !important;
  color: #333333 !important;
  text-align: justify !important;
}

.cntry__menu-list {
  max-height: 10rem !important;
}

.identification-data-container,
.identification-data-container h3 {
  font-size: 16px !important;
}

.identification-data-container .row {
  margin-bottom: 10px !important;
}

.r1 {
  display: flex !important;
  flex-direction: row !important;
  justify-content: space-between !important;
  align-items: center !important;
  margin-bottom: 10px !important;
}

.c {
  flex: 0.32;
  margin-bottom: 20px;
}

.mc-comp {
  margin-left: 2px;
}

.c1 .c2 {
  margin-right: 10px;
}

.cpad {
  padding: 24px !important;
}

.c1 .c2 .c3 {
  padding: 10px;
  flex: 0.32;
}

.cardInfo {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.signature-container-img {
  max-width: 100% !important;
  width: 100% !important;
}

.user-dashboard-content {
  min-height: 90.6vh !important;
  background-color: var(--background-color-two);
}

.user-dashboard-menu {
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.05);
  background-color: var(--background-color-three) !important;
  position: fixed !important;
  left: 0 !important;
  bottom: 0 !important;
  z-index: 99 !important;
  top: 90px !important;
}

.text-black {
  color: var(--text-color) !important;
}

.only-black {
  color: #000;
}

.user-dashboard-menu li span {
  color: var(--text-color-two) !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  opacity: 1 !important;
}

.header-fixed-menu {
  position: fixed !important;
  width: 100% !important;
  top: 0 !important;
  z-index: 100 !important;
}

.center-content {
  padding-top: 90px !important;
}

.terms-center-content {
  padding-top: 90px !important;
}

.card-inner-padding {
  padding: 24px;
}

.user-dashboard-content {
  margin-left: 220px !important;
  position: relative !important;
}

.menu-display {
  display: block;
  transition-duration: 0.3s;
}

.alert-dark-blue {
  background-color: #395cbe !important;
  border-color: #395cbe !important;
  color: white !important;
  font-size: 18px !important;
}

.alert-white {
  background-color: white !important;
  border-color: white !important;
  color: black !important;
  font-size: 18px !important;
  box-shadow: 0px 0px 16px rgb(0 0 0 / 5%);
}

.worth-highlighter {
  font-size: 13px !important;
  color: #d8cdcd !important;
}

.content-box {
  min-height: 80vh !important;
  padding-bottom: 0 !important;
}

.progressbar li:after {
  width: calc(100% - 30px) !important;
  margin-left: 15px;
  margin-right: 15px;
}

.buyXprNotes {
  padding: 40px !important;
}

.buyXprNotes ol {
  padding-inline-start: 15px;
}

.incrementNo li:before {
  content: counters(item, ".") " ";
  counter-increment: item;
}

.instructon-modal-container OL {
  counter-reset: item;
}

.instructon-modal-container LI {
  display: block;
}

.instructon-modal-container LI:before {
  content: counters(item, ".") " ";
  counter-increment: item;
}

.clearNumber {
  counter-reset: list !important;
}

.clearNumber .incrementNo li {
  list-style: none !important;
  position: relative !important;
}

.clearNumber li::before {
  counter-increment: list !important;
  content: "(" counter(list, lower-alpha) ") " !important;
}

.clearAlpha {
  counter-reset: list !important;
}

.clearAlpha li {
  list-style: none !important;
  position: relative !important;
}

.clearAlpha li::before {
  counter-increment: list !important;
  content: "(" counter(list, lower-roman) ") " !important;
}

.clearNumbers {
  counter-reset: number !important;
}

.clearNumbers li::before {
  counter-increment: number !important;
  content: " " counter(number) ". " !important;
}

.underline {
  text-decoration: underline !important;
}

.instructionCheck {
  margin-left: 1rem !important;
  display: inline !important;
  margin-bottom: 1rem !important;
}

.declarationCheck {
  float: left !important;
}

.declarationLabel {
  display: block !important;
  margin-left: 40px !important;
}

.my-account-error-msg {
  font-size: 16px !important;
  border: 1px solid red;
}

.my-account-error-msg a {
  color: white !important;
  text-decoration: underline !important;
}

.myaccount-error-icon {
  color: red !important;
  font-size: 20px !important;
}

.assumption-block {
  color: black !important;
}

.listing-text-input {
  display: inline-block;
  border: 1px solid white;
  border-radius: 50px;
}

.listing-text-input label {
  padding-left: 10px;
  vertical-align: center;
  text-align: center;
  color: white;
}

.listing-text-input .form-control {
  width: 125px;
  border-radius: 50px;
}

.cusor-pointer {
  cursor: pointer;
}

.listing_file {
  opacity: 0;
  position: absolute;
  z-index: -1;
}

.form-check {
  padding-left: 10px !important;
}

.upload-button {
  background: transparent;
  border-radius: 50px;
  border: 1px solid white;
  color: white;
}

.button-container {
  padding: 2px 5px !important;
}

.file-upload-border:nth-child(even) {
  border-left: 1px solid white;
  padding-left: 25px !important;
}

.file-upload-border:last-child {
  padding-bottom: 0.5rem !important;
}

.listing-files-container i.filesuccess {
  border: 1px solid white;
  padding: 5px;
  border-radius: 50px;
}

.listing-files-container i.fileerror {
  border: 1px solid white;
  padding: 5px;
  border-radius: 50px;
  background: red;
}

.listing-location {
  border-radius: 50px;
  border: 1px white;
  width: 60%;
  height: 32px;
  float: left;
}

.mark-on-map {
  background: transparent;
  margin-left: 13px;
  border-radius: 50px;
  border-color: white;
  border: 1px solid white;
  height: 32px;
  color: white;
  padding: 0px;
  padding: 2px 10px;
}

.button-center {
  display: flex;
  justify-content: center;
}

.payment-methods-container span {
  padding-left: 5px;
}

.payment-methods-container label:not(:first-child) {
  padding-left: 10px;
}

.listing-modal-container,
.listing-modal-container .modal-lg {
  max-width: 100% !important;
}

.location-autosuggest-container input {
  margin-bottom: 0px !important;
  border-radius: 50px !important;
  border: 1px white !important;
  width: 70% !important;
  height: 32px !important;
  float: left !important;
  color: #395cbe !important;
}

.listing-label {
  padding-right: 0.5rem !important;
  font-size: 15px;
}

.unit-selector {
  padding: 0 5px !important;
  font-size: 16px;
}

.submit-listing-container :focus {
  outline: none !important;
}

.listing-header-title span {
  border-bottom: 1px solid #59adde !important;
}

.average-listing-input {
  width: auto !important;
  border-radius: 50px !important;
}

.progressreportadd .filepond--drop-label {
  color: white !important;
  color: var(--text-color-two) !important;
}

.progressreportadd .filepond--root {
  border: 2px solid #dde2ec !important;
}

.filepond--root {
  border: 2px dashed #ebebeb !important;
}

.filepond--root label {
  color: var(--text-color-two) !important;
  font-size: 16px !important;
  font-weight: bold !important;
}

.progressreportadd .form-buttons-w {
  margin-top: 0 !important;
  padding-top: 0 !important;
  border-top: none !important;
}

.progressreportadd .element-wrapper {
  padding-bottom: 0 !important;
}

.colorwhite {
  color: white !important;
}

.listing-files-container.files-container {
  overflow: hidden !important;
}

.update-payment-method-input {
  width: 100% !important;
  padding-left: 15px !important;
  height: 30px !important;
  border-radius: 50px 0px 0px 50px;
  opacity: 1 !important;
  border: white !important;
  font-size: 16px;
  font-weight: 500;
  padding-right: 10px;
}

.update-payment-method-input:focus {
  outline: none !important;
}

.vertical_center {
  margin: auto 0;
}

.permian-button:active,
.permian-button:hover {
  color: white;
}

.onboarding-modal .onboarding-content h4 {
  text-align: left;
  color: #333333;
}

.my-account-button {
  margin-right: 8px !important;
}

.xs-display-equal {
  display: none;
}

.instructon-modal-container {
  padding: 0 !important;
  border: 1px solid #ebebeb;
  box-sizing: border-box;
  box-shadow: 0px 0px 16px rgb(0 0 0 / 5%);
  border-radius: 8px;
  position: relative;
  max-width: 40% !important;
  min-width: 40% !important;
}

.modal-content form {
  min-width: 30rem;
}

.progress-category-select2 ::placeholder,
.progressreport__single-value,
.progressreport__placeholder,
.progressreport__indicators svg {
  color: #495057 !important;
}

.xpr-balance-display {
  padding: 5px;
  border: 1px solid #395cbe;
  border-radius: 50px;
}

body.page-loaded .css3-spinner {
  display: none !important;
}

.signup-button {
  margin-bottom: 0rem !important;
}

.width-35 {
  width: 35% !important;
}

.signuppt {
  padding-top: 2.5rem !important;
}

.signinpt {
  padding-top: 3.5rem !important;
}

@media screen and (min-width: 180px) and (max-width: 961px) {
  .signuppage-info-bottom-link {
    width: 100%;
    text-align: center;
  }
  .signin-info-bottom-link {
    text-align: center !important;
  }
  .width-100 {
    width: 100% !important;
  }
  .btn-center-group {
    display: flex;
    justify-content: center;
  }
}

@media (min-width: 576px) {
  .modal-dialog {
    margin: 0 !important;
  }
  .selectpad {
    padding-right: 18px !important;
  }
}

@media (max-width: 991px) {
  .text-center-mobile .hashrate-card {
    padding-right: 0px !important;
  }
  .mining-btn-center {
    display: flex;
    justify-content: center !important;
  }
  .agreementQR {
    display: flex !important;
    flex-direction: column;
  }
}

@media screen and (max-width: 460px) {
  .dashboard_text_space {
    margin-left: 20px !important;
  }
}

@media screen and (max-width: 450px) {
  .col-xxs-12 {
    width: 100%;
  }
  .xxs-earning-card-margin {
    margin-top: 1rem;
  }
  .tb-margin {
    margin-top: 3px !important;
  }
}

@media screen and (max-width: 575px) {
  .confirmationBox {
    display: flex !important;
    flex-direction: column !important;
  }
  .update-button {
    margin-bottom: 8px;
  }
  .kyc-content {
    height: 50vh;
  }
  .termsHeading {
    font-size: 18px !important;
    margin-left: 10px !important;
  }
}

@media screen and (min-width: 451px) and (max-width: 575px) {
  .col-xs-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media screen and (max-width: 648px) {
  .table-striped tbody tr td span,
  .table-lightfont tbody tr td span {
    margin-right: 10px !important;
  }
}

@media screen and (max-width: 479px) {
  .text-right {
    text-align: left !important;
  }
}

@media screen and (min-width: 480px) and (max-width: 767px) {
  .custom-top-bar {
    display: none !important;
  }
  .center-content {
    padding: 0 !important;
  }
  .menu-container {
    margin-top: -20px !important;
  }
  .text-right {
    margin-top: -30px !important;
  }
}

@media screen and (min-width: 280px) and (max-width: 480px) {
  .auth-box-w.wider,
  .auth-box-w.login-box {
    border-radius: 0px !important;
  }
  .earningTooltip {
    width: 200px !important;
  }
  .kyc-button-group {
    display: flex !important;
    flex-direction: column !important;
  }
  .walletTooltip {
    width: 200px !important;
  }
  .walletTooltip1 {
    width: 240px !important;
  }
  .stripeTooltip {
    width: 110px !important;
    padding: 10px;
  }
  .wireTooltip {
    width: 110px !important;
    padding: 10px;
  }
  .auth-box-w form,
  .auth-box-w.login-box form {
    padding: 20px 20px 40px 20px !important;
  }
  .auth-box-w {
    background-color: transparent !important;
  }
  .confirmPasswordContainer {
    margin-top: 1rem !important;
  }
  .terms-block,
  .info-bottom-link {
    font-size: 14px !important;
  }
  .copyright-text {
    height: 100%;
    line-height: 20px !important;
    font-size: 14px !important;
    padding-bottom: 10px !important;
  }
  .main-content-container {
    margin-top: 0px !important;
  }
  .custom-form .input-group-text {
    top: 10% !important;
    padding: 10px !important;
  }
  .custom-form .form-control {
    padding: 12px !important;
  }
  .wrapper {
    margin-top: 10% !important;
  }
  .top-bar:not(.admin-top-bar) .top-menu-controls {
    padding: 10px 0 !important;
  }
  .split-radio-container label,
  .wealth-split-radio-container label,
  .understanding-split-radio-container label {
    min-width: 100% !important;
  }
  .main-content-container {
    padding-top: 0 !important;
  }
  .menu-container {
    background-image: linear-gradient(to bottom, #1c4cc3 0%, #1c2e7b 100%);
    background-repeat: repeat-x;
    color: var(--background-color-ten);
    margin-top: -10px;
  }
  .buy-xpr-currencey-select {
    width: 6% !important;
    height: 35px !important;
    border-radius: 0px 8px 8px 0px;
    background-color: transparent;
    border-color: #ebebeb !important;
    padding: 0 2px !important;
    font-size: 14px;
  }
  .custom-top-bar {
    display: none !important;
  }
  .customLogo {
    margin-left: 0 !important;
  }
  .menu-mobile .mm-logo-buttons-w .mm-logo img {
    width: auto !important;
  }
  .dashboard-statistics-container:not(:last-child),
  .user-statistics-container {
    border-right: none !important;
  }
  .buy-xpr-header-block {
    font-size: 14px !important;
  }
  .dashboard-statistics-container {
    margin: 10px 0 !important;
  }
  .my-account-details:not(:last-child) {
    margin-bottom: 20px !important;
  }
  .onboarding-modal .onboarding-slide .onboarding-content {
    padding: 15px 25px !important;
  }
  .newonboarding-modal .newonboarding-slide .newonboarding-content {
    padding: 0px 10px !important;
  }
  .my-account-container h5 span {
    font-weight: 0.8rem !important;
  }
  .my-account-container h5 span.pull-right {
    margin-top: 0.4rem !important;
  }
  .two-factor-block {
    text-align: center !important;
  }
  .pull-right {
    margin-top: 1rem !important;
    margin-bottom: 0.9rem !important;
  }
  .two-factor-heading-text,
  .twofa-submit-button {
    margin-top: 15px !important;
  }
  .two-factor-heading-header {
    margin-top: 40px !important;
  }
  .listing-reserves-input,
  .tokens-xpr-input {
    width: 15% !important;
    padding-left: 5px !important;
    font-size: 14px !important;
    padding-right: 5px !important;
  }
  .buy-xpr-block {
    padding: 0.8rem !important;
  }
  .buy-xpr-container {
    font-size: 16px !important;
  }
  .converter-sign {
    padding-left: 0.5rem !important;
  }
  .xpr-tokens-label {
    padding-left: 0.5rem !important;
  }
  .buy-xpr-label {
    padding-right: 0.5rem !important;
    margin-top: 0.3rem !important;
  }
  .token-symbol-input {
    width: 10% !important;
  }
  .eth-contribution-input {
    width: 100% !important;
    margin-top: 10px !important;
  }
  .buy-xpr-buttons {
    padding: 5px 15px !important;
    margin-top: 10px !important;
    margin-left: 0 !important;
  }
  .buy-online-payment-buttons {
    width: auto !important;
    margin-top: 10px !important;
  }
  .user-dashboard-content {
    min-height: 100vh !important;
  }
  .wallet-header {
    font-size: 14px !important;
  }
  .buy-xpr-container h6 {
    font-weight: 200 !important;
  }
  .identification-header {
    font-size: 18px !important;
  }
  .kyc-container.mt-4 {
    margin-top: 15px !important;
  }
  .identification-block .permian-button {
    padding: 15px 45px !important;
  }
  .country-top-margin {
    margin-top: 15px !important;
  }
  .our-service-container .iradio_square-blue {
    margin-left: 0px !important;
  }
  .form-check {
    padding-left: 0px !important;
  }
  .width-100 {
    width: 100% !important;
  }
  .pl-none {
    padding-left: 0px !important;
  }
  .pdf-content p {
    font-size: 13px !important;
  }
  .pdf-content h6 {
    font-size: 15px !important;
  }
  .pdf-content th,
  .pdf-content td,
  .pdf-content li {
    font-size: 12px !important;
  }
}

@media screen and (min-width: 455px) and (max-width: 480px) {
  .pull-right {
    margin-top: 0.4rem !important;
  }
}

.text-success-bold {
  color: #00f700;
}

.text-danger-bold {
  color: #ff3400;
}

.custom-table-th {
  font-size: 12px !important;
  font-weight: 800 !important;
  color: var(--text-color);
}

.form-table-header {
  color: var(--text-color);
  font-weight: normal;
}

.form-table-link {
  color: #404040;
  text-transform: capitalize;
  text-decoration: underline;
  font-size: 1.1rem;
  font-weight: 300;
}

.table-head {
  border-bottom: 2px solid;
}

.table-td-no-border td {
  border: none;
}

.black-color {
  color: #000;
}

.blue-link {
  color: #003cff;
  text-decoration: underline;
}

.no-padding {
  padding: 0 !important;
}

.no-margin {
  margin: 0 !important;
}

.min-table {
  min-height: 350px;
}

.buy-xpr-input {
  padding-left: 20px !important;
  height: 35px !important;
  border-radius: 8px 0px 0px 8px;
  opacity: 1 !important;
  border: 1px solid #ebebeb !important;
  font-size: 16px;
  font-weight: 500;
  text-align: right;
  padding-right: 10px;
}

.buy-xpr-input:focus {
  border: 2px solid #ced2d6 !important;
  box-shadow: 0 0 2px #ced2d6 !important;
  outline: none !important;
}

.custom-select-container {
  border: 1px solid white;
  border-radius: 10px;
  width: 100%;
}

.border-transparent,
.border-transparent:focus {
  border-color: transparent !important;
  border: none;
  outline: none;
}

.border-radius-50px {
  border-radius: 50px !important;
}

.h-35 {
  height: 35px !important;
}

.bg-darkCyan {
  background: #ffffff !important;
}

.custom-select-icon {
  display: block;
  width: 100%;
  padding: 2px 25px 2px 2px;
  border: none;
  background: url("../img/chevron-down-solid.svg") right center no-repeat;
  appearance: none;
}

.calculate-btn {
  display: flex;
  border-radius: 8px !important;
  background-color: #395cbe !important;
  margin: 10px 0 !important;
  padding-right: 0px !important;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  border: 1px solid #395cbe !important;
  font-size: 18px !important;
  width: 170px !important;
  height: 40px !important;
  color: white !important;
}

.card-title-font {
  font-size: 20px;
}

.pl-xl {
  padding-left: 5rem;
}

.w-65 {
  width: 65% !important;
}

.w-80 {
  width: 80% !important;
}

.w-70 {
  width: 70% !important;
}

.w-35 {
  width: 35% !important;
}

.w-40 {
  width: 40% !important;
}

.w-20 {
  width: 20% !important;
}

.font-weight-200 {
  font-weight: 200 !important;
}

.normal-text {
  text-transform: none !important;
  font-weight: 400 !important;
}

.white-lighter-text {
  font-weight: 300 !important;
  font-size: 22px !important;
}

.buy-container {
  margin: 2rem;
}

.col-custom {
  width: 170px;
}

.balance-container {
  width: 25%;
  border: 1px solid #395cbe;
  border-radius: 50px;
  padding: 8px 16px;
  text-align: center;
}

.buy-btn {
  border-radius: 50px !important;
  background-color: #395cbe !important;
  border: none !important;
  font-size: 18px !important;
  color: white;
  height: 48px !important;
  padding: 0 24px;
}

.theme-bg {
  background: #395cbe !important;
}

.h-50 {
  height: 50px !important;
}

.w-200 {
  width: 200px !important;
}

.theme-border {
  border: 1px solid #395cbe !important;
  outline: none;
}

.w-250 {
  width: 250px !important;
}

.btn-left-icon {
  position: absolute;
  left: 12px;
}

.btn-right-icon {
  position: absolute;
  right: 12px;
  bottom: 12px;
}

.white-round {
  height: 25px;
  width: 25px;
  background: white;
  border-radius: 50%;
}

.theme-color {
  color: var(--text-color-four) !important;
}

.vertical-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.select-address-container {
  border: 1px solid white;
  padding: 5px 12px;
  border-radius: 50px;
}

.font-20 {
  font-size: 20px;
}

.white-border-input {
  background: transparent;
  border: 1px solid white;
  width: 100%;
  border-radius: 50px;
  height: 40px;
  outline: none;
  padding: 5px 16px;
  color: white;
}

.custom-radio-group {
  display: flex;
  flex-direction: row;
}

.radio-group-label {
  display: flex;
  align-items: center;
  color: white;
}

.radio-group-button {
  height: 40px;
  width: 40px;
}

.radio-group-text {
  margin-left: 16px;
  font-size: 20px;
  font-weight: 200 !important;
}

.edit-round {
  height: 25px;
  width: 25px;
  border: 1px solid white;
  border-radius: 50%;
}

.font-15 {
  font-size: 15px;
}

.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: none;
}

.btn-secondary:not(:disabled):not(.disabled):active:focus,
.btn-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: none;
}

.btn-secondary:not(:disabled):not(.disabled) {
  background: #395cbe;
  border-radius: 8px;
  border-color: #ffffff;
}

.btn-secondary:not(:disabled):not(.disabled):active {
  background: #395cbe;
  border-radius: 8px;
  border-color: #ffffff;
}

.order-row {
  display: flex;
  flex-wrap: wrap;
  border-bottom: 1px solid white;
  margin-left: 1rem;
  padding: 0 0.5rem;
  margin-right: 1rem;
}

.check-round {
  height: 80px;
  width: 80px;
  background: #395cbe !important;
  border-radius: 50%;
  font-size: 50px;
  color: white;
}

.top-margin-dashboard {
  margin-top: 1.4rem !important;
}

.top-margin {
  margin-top: 1.9rem !important;
}

.my-account-details {
  padding-right: 16px;
}

.table-space {
  padding: 18px 24px !important;
}

.extra-space {
  margin-bottom: 26px !important;
}

.order-msg {
  color: black;
  font-size: 20px;
  font-weight: 400;
}

.sidebar-user {
  color: #a4a4a4;
}

.sidebar-user-name {
  font-size: 18px;
}

.sidebar-user-panel {
  font-size: 11px;
}

.border-color-user {
  border-color: #a4a4a4 !important;
}

.btn-down {
  height: 30px;
  width: 30px;
  border-radius: 8px;
}

.sidebar-width {
  width: 270px;
}

.sidebar-menu-name li span {
  font-size: 16px !important;
  font-weight: 700 !important;
}

.table-buy-btn {
  height: 34px !important;
  text-decoration: underline;
  font-size: 14px !important;
  width: 85px;
  outline: none !important;
}

.user-dashboard-content {
  overflow: hidden;
}

.menu-w {
  background-color: #395cbe;
}

.mining-table-minimum {
  min-height: 100px;
}

.blur-bg {
  backdrop-filter: blur(10px);
  background-color: rgba(255, 255, 255, 0.5);
  filter: blur(4px);
}

.font-size-12 {
  font-size: 12px !important;
}

.centerofthescreen {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.min-height-90vh {
  min-height: 90vh !important;
}

.min-width-40 {
  min-width: 40% !important;
}

.form-check {
  padding-left: 10px !important;
}

.mining-result-table tr td {
  font-size: 14px !important;
}

@media only screen and (min-width: 1424px) and (max-width: 1624px) {
  .dashboard_text_issue {
    font-size: 20px;
  }
}

@media only screen and (min-device-width: 481px) and (max-device-width: 575px) {
  .confirmPasswordContainer {
    margin-top: 16px;
  }
  .width-100 {
    width: 100% !important;
  }
}

.dashboard-text-title {
  padding: 0 0.6rem !important;
}

.padding-10 {
  padding: 10px !important;
}

.hashrate-card {
  padding-right: 14px !important;
}

.countdown .card {
  background: #333;
  display: inline-block;
  margin: 10px;
  min-width: 100px;
  padding: 20px 0;
}

.countdown .card .countdown-value {
  color: #00ffcc;
  font-size: 2rem;
  margin-bottom: 10px;
}

.countdown .card .countdown-unit {
  text-transform: capitalize;
  color: #fff !important;
}

.bg-white {
  background-color: white;
}

.dashboard-text-bold {
  font-size: 16px;
  font-weight: 600;
}

@media only screen and (min-width: 1327px) and (max-width: 1621px) {
  .dashboard_text_space {
    font-size: 13px;
  }
}

@media (min-width: 1200px) {
  .mgtp-xl-3 {
    margin-top: 1rem;
  }
  .mgtp-xl-4 {
    margin-top: 1.5rem;
  }
}

@media screen and (min-width: 577px) and (max-width: 777px) {
  .mgtp-sm-4 {
    margin-top: 1.5rem;
  }
  .mgtp-sm-3 {
    margin-top: 1rem;
  }
}

@media screen and (min-width: 180px) and (max-width: 576px) {
  .mgtp-xs-4 {
    margin-top: 1.5rem;
  }
  .mgtp-xs-3 {
    margin-top: 1rem;
  }
  .QrRow {
    flex-direction: column !important;
  }
}

@media screen and (max-width: 820px) {
  .mgtp-md-4 {
    margin-top: 1.5rem;
  }
  .mgtp-md-3 {
    margin-top: 1rem;
  }
  .rowInput {
    flex-direction: column !important;
    align-items: flex-start !important;
  }
  .rowInput input {
    margin-bottom: 10px !important;
    margin-left: 0px !important;
  }
}

@media screen and (min-device-width: 768px) and (max-device-width: 991px) {
  .mgtp-md-4 {
    margin-top: 1.5rem;
  }
  .mgtp-md-3 {
    margin-top: 1rem;
  }
  .hide-eshop,
  .hide-dark-mode {
    display: none !important;
  }
}

@media screen and (max-width: 836px) {
  .kyc-alert-container {
    flex-direction: column;
  }
  .kyc-alert-container > div:last-of-type {
    margin-left: 30px;
  }
}

@media screen and (max-width: 661px) {
  .kyc-alert-container img {
    height: 45px;
  }
  .kyc-alert-container > div:last-of-type {
    margin-left: 43px;
    margin-top: 5px;
  }
}

@media only screen and (min-device-width: 992px) and (max-device-width: 1200px) {
  .mgtp-lg-3 {
    margin-top: 1rem;
  }
  .mgtp-lg-4 {
    margin-top: 1.5rem;
  }
}

@media only screen and (min-width: 1620px) and (max-width: 1727px) {
  .dashboard_text_space {
    font-size: 20px;
  }
}

@media (max-width: 491px) {
  .hashrate-card-d-block {
    display: block !important;
  }
  .hashrate-card-button {
    margin-top: 20px;
    display: flex;
    text-align: center;
    width: 100%;
  }
  .hashrate-card-text {
    align-items: center;
    display: flex;
  }
}

@media (max-width: 575px) {
  .Budget-cards {
    padding: 0.5rem !important;
  }
  .dashboard-text-bold {
    font-size: 14px;
    font-weight: 600;
  }
  .worker-content {
    margin-bottom: 0.5rem !important;
  }
  .Hashrate-content,
  .worker-content,
  .Earnings-content {
    width: 50% !important;
  }
  .Card-d-block-none {
    display: none;
  }
  .dasboard-workers-card {
    margin-bottom: 18px;
    margin-top: 25px;
  }
  .pdf-content {
    max-height: 400px !important;
  }
}

.menu-container {
  background-color: var(--background-color-ten);
}

.mining-extra-margin {
  margin-top: 1rem;
}

@media screen and (max-width: 1100px) and (min-width: 1026px) {
  .dashboard-text-padding .Earnings-text,
  .dashboard-text-padding .Workers-text {
    margin-left: 26px !important;
  }
}

@media screen and (max-width: 420px) {
  .permian-button {
    width: 45%;
  }
  .mining-margin-top {
    margin-top: 0px !important;
  }
  .mining-extra-margin {
    margin-top: 0;
  }
  .modal-button {
    padding: 0px 15px;
  }
  .calculateContainer {
    flex-direction: column !important;
  }
}

@media screen and (max-width: 381px) {
  .kyc-alert-container img {
    height: 55px;
  }
  .kyc-alert-container > div:last-of-type {
    margin-left: 50px;
    margin-top: 10px;
  }
}

@media screen and (max-width: 575px) {
  .instructon-modal-container {
    max-width: 90% !important;
  }
  .newinstructon-modal-container {
    max-width: 100% !important;
  }
  .user-dashboard-content {
    margin-left: 0 !important;
  }
}

@media screen and (max-width: 1024px) and (min-width: 576px) {
  .instructon-modal-container {
    max-width: 70% !important;
  }
  .user-dashboard-content {
    margin-left: 0 !important;
  }
  .onboarding-content {
    min-width: 60vw !important;
  }
}

@media screen and (max-width: 767px) {
  .dashboard-statistics-container:not(:last-child),
  .user-statistics-container {
    border-right: none !important;
  }
  .content-mobile-margin {
    margin-top: 0px !important;
  }
  .top-margin-dashboard {
    margin-top: 0px !important;
  }
  .top-margin {
    margin-top: 0px !important;
  }
  .mining-margin-top {
    margin-top: 0px !important;
  }
  .instructon-modal-container {
    max-width: 90% !important;
  }
  .newinstructon-modal-container {
    max-width: 100% !important;
  }
  .onboarding-content {
    min-width: 85vw !important;
  }
}

@media screen and (max-width: 480px) {
  .center-content {
    padding-top: 0px !important;
  }
  .card-title-font {
    font-size: 20px;
  }
  .exportData {
    justify-content: flex-start !important;
  }
}

@media screen and (min-width: 1025px) {
  .ml-2rem {
    margin-left: 1.6rem !important;
  }
  .instructon-modal-container {
    max-width: 90% !important;
  }
  .h-55 {
    max-width: 55% !important;
  }
}

@-moz-document url-prefix() {
  .min-table {
    min-height: auto !important;
  }
  .no-padding {
    padding: 50px 0px !important;
  }
  .power-sites-table {
    height: 341px;
  }
}

.fa-spinner {
  margin-top: 0px !important;
}

@media screen and (min-width: 768px) and (orientation: portrait) {
  .content-box {
    min-height: 88vh !important;
  }
  .user-dashboard-content {
    min-height: 96vh !important;
  }
  .main-menu {
    display: block !important;
  }
}

.font-14 {
  font-size: 14px;
}

.cursor-pointer:hover {
  background-color: var(--background-color-five) !important;
}

.menu_active {
  background-color: var(--background-color-five) !important;
}

.mobile_menu_active {
  background-color: #0b2a82;
}

.theme-dark-color {
  background-color: var(--background-color-one);
}

.color-black {
  color: var(--text-color);
}

.mining-margin-top {
  margin-top: 30px;
}

.paddingLeft6 {
  padding-left: 6px !important;
}

.active-currency {
  background: var(--background-color-five);
}

.earnings-select {
  height: 28px !important;
  border-radius: 8px;
  background-color: #ffffff;
  border: 1px solid #395cbe !important;
  font-size: 12px !important;
  margin-left: 12px;
  color: #395cbe;
}

@media screen and (max-width: 1395px) {
  .c {
    flex: 0.31 !important;
  }
  .c3 {
    flex: 0.35 !important;
  }
}

@media screen and (max-width: 1326px) {
  .c2 {
    flex: 0.28 !important;
  }
  .c1 .c2 {
    margin-right: 5px !important;
  }
  .c3 {
    flex: 0.38 !important;
  }
}

@media screen and (max-width: 1310px) {
  .c2 {
    flex: 0.25 !important;
  }
  .c1 .c2 {
    margin-right: 5px !important;
  }
  .c3 {
    flex: 0.4 !important;
  }
}

@media screen and (max-width: 1260px) {
  .c2 {
    flex: 0.28 !important;
  }
  .c3 {
    flex: 0.4 !important;
  }
  .dashboard-text-bold {
    font-size: 14px;
  }
}

@media screen and (max-width: 1170px) {
}

.c1 {
  flex: 0.32 !important;
}

.c2 {
  flex: 0.28 !important;
}

.c3 {
  flex: 0.37 !important;
}

@media screen and (max-width: 1330px) {
  .icon {
    height: 30px !important;
    width: 30px !important;
  }
  .c1 {
    flex: 0.32 !important;
  }
  .c2 {
    flex: 0.25 !important;
  }
  .c3 {
    flex: 0.41 !important;
  }
}

@media screen and (max-width: 1280px) {
  .icon {
    height: 30px !important;
    width: 30px !important;
  }
  .c1 {
    flex: 0.31 !important;
  }
  .c2 {
    flex: 0.24 !important;
  }
  .c3 {
    flex: 0.43 !important;
  }
}

@media screen and (max-width: 1258px) {
  .c1 {
    flex: 0.31 !important;
  }
  .c2 {
    flex: 0.24 !important;
  }
  .c3 {
    flex: 0.43 !important;
  }
}

@media screen and (max-width: 1234px) {
  .c1 {
    flex: 0.3 !important;
  }
  .c2 {
    flex: 0.24 !important;
  }
  .c3 {
    flex: 0.44 !important;
  }
}

@media screen and (max-width: 1210px) {
  .c1 {
    flex: 0.29 !important;
  }
  .c2 {
    flex: 0.24 !important;
  }
  .c3 {
    flex: 0.45 !important;
  }
}

@media screen and (max-width: 1195px) {
  .r1 {
    display: flex !important;
    flex-direction: column !important;
    align-items: stretch !important;
  }
  .dashboard-text-bold {
    font-size: 16px !important;
  }
}

.clickwrap-list li {
  display: list-item !important;
}

.clickwrap-list li:before {
  content: none !important;
}

.newonboarding-content {
  padding: 0px !important;
}

.resource-continaer label {
  color: white !important;
}

.collapLi {
  display: flex !important;
  margin-left: 10px !important;
  padding: 10px 0px !important;
}

.collapLi a,
.collapLi div span {
  color: white !important;
  font-weight: normal !important;
}

.shopping-icon a {
  color: #fff;
  font-size: xxx-large;
  cursor: pointer;
}

.shopping-icon:hover {
  transform: scale(1.1) translate(-6px, -3px);
  transition: 0.2s;
}

input {
  background: #fff !important;
}

.text-gray {
  color: var(--text-color-three);
}

.questionColor {
  filter: invert(var(--invert-filter)) !important;
}

.mui-field {
  border-radius: 5px;
  padding: 15px 10px;
  outline: none !important;
  border: 1px solid rgb(202, 203, 206);
}

.element-wrapper .custom-element-box {
  background: var(--background-color-three) !important;
}

.resource-card-bg {
  background: var(--background-color-ten) !important;
}

.budget-background {
  background: var(--background-color-three) !important;
}

.gray {
  color: var(--text-color-six) !important;
}

.InputElement {
  color: var(--text-color) !important;
}

[data-theme="dark"] {
  --background-color-one: #333 !important;
  --background-color-two: #444 !important;
  --background-color-three: #555 !important;
  --background-color-four: #444 !important;
  --background-color-five: #666 !important;
  --background-color-six: #444 !important;
  --background-color-seven: #333 !important;
  --background-color-eight: #333 !important;
  --background-color-nine: #555 !important;
  --background-color-ten: #444 !important;
  --background-image-one: linear-gradient(
    to bottom,
    #1d253b 0%,
    #0c1125 100%
  ) !important;
  --text-color: #fff;
  --text-color-two: #fff;
  --text-color-three: #fff;
  --text-color-four: #97faff;
  --text-color-five: #fff;
  --text-color-six: #fff;
  --invert-filter: 100%;
}

[data-theme="light"] {
  --background-color-one: #395cbe !important;
  --background-color-two: #fff !important;
  --background-color-three: #fff !important;
  --background-color-four: #fafafa !important;
  --background-color-five: #ebebeb !important;
  --background-color-six: #f7f9fa !important;
  --background-color-seven: #e9e9e9 !important;
  --background-color-eight: #efefef !important;
  --background-color-nine: #395cbe !important;
  --background-color-ten: white !important;
  --background-image-one: linear-gradient(
    to bottom,
    #1c4cc3 0%,
    #1c2e7b 100%
  ) !important;
  --text-color: #333;
  --text-color-two: #395cbe;
  --text-color-three: #334152;
  --text-color-four: #395cbe;
  --text-color-five: #f8f8f8;
  --text-color-six: gray;
  --invert-filter: 50%;
}
