.bg-back {
  color: black;
}

.bg-darkgrey {
  color: rgba(0, 0, 0, 0.15);
}

#react-select-2-option-0,
#react-select-2-option-1,
#react-select-2-option-2,
#react-select-2-option-3,
#react-select-2-option-4 {
  color: var(--text-color) !important;
  background-color: var(--background-color-three);
}

#react-select-2-option-0:hover,
#react-select-2-option-1:hover,
#react-select-2-option-2:hover,
#react-select-2-option-3:hover,
#react-select-2-option-4:hover {
  color: var(--text-color) !important;
  background-color: var(--background-color-five);
}
