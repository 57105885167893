.budgetUpdateCardContainer {
  border: 1px solid rgb(204, 201, 201) !important;
  border-radius: 5px !important;
  padding: 20px 15px !important;
}

.budgetContainer {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.width500 {
  width: 500px !important;
}

.editCardCollapse {
  width: 100% !important;
}

.expiryLabel::before {
  content: "Expiry Date *";
  font-size: 12px !important;
  position: relative !important;
  top: 20px !important;
  left: 10px !important;
  z-index: 9;
  color: rgb(170, 167, 167) !important;
  background-color: white;
  padding-left: 5px;
  padding-right: 5px;
}

.editImg {
  position: absolute !important;
  /* top: 10px !important; */
  background: white !important;
  margin-top: 5px !important;
  cursor: pointer !important;
  width: 20px !important;
  height: 20px !important;
  z-index: 100;
}

.editSelectImg {
  position: absolute !important;
  /* top: 10px !important; */
  background: white !important;
  margin-top: -10px !important;
  cursor: pointer !important;
  width: 20px !important;
  height: 20px !important;
  z-index: 1100;
}

.editExpiryImg {
  position: absolute !important;
  /* top: 10px !important; */
  background: white !important;
  margin-top: 20px !important;
  cursor: pointer !important;
  width: 20px !important;
  height: 20px !important;
  z-index: 100;
}

.changeCardContainer {
  display: block !important;
}

.InputElement {
  color: var(--text-color) !important;
}

.b-icon-margin {
  margin-right: 50px;
}

.s-icon-margin {
  margin-right: 55px;
}

@media screen and (max-width: 474px) {
  .b-icon-margin {
    margin-right: 25px;
  }
  .s-icon-margin {
    margin-right: 30px;
  }
}

@media screen and (max-width: 374px) {
  .b-icon-margin {
    margin-right: 15px;
  }
  .s-icon-margin {
    margin-right: 20px;
  }
}
