.succes-container {
  width: 50% !important;
  margin: 150px auto !important;
  border-radius: 10px !important;
  height: fit-content !important;
  padding-bottom: 20px !important;
  background: white !important;
  box-shadow: 2px 2px 20px gray !important;
}

.success-user-details {
  width: 50%;
  margin: 0 auto !important;
  text-align: center !important;
}

.cancel-img {
  position: relative;
  width: 200px !important;
  height: 200px !important;
  margin-top: -120px;
}

@media screen and (max-width: 1537px) {
  .success-user-details {
    width: 55%;
  }
}

@media screen and (max-width: 1450px) {
  .succes-container {
    width: 60% !important;
  }
}

@media screen and (max-width: 1160px) {
  .succes-container {
    width: 70% !important;
  }
  .success-user-details {
    width: 65%;
  }
}

@media screen and (max-width: 860px) {
  .succes-container {
    width: 80% !important;
  }
}

@media screen and (max-width: 679px) {
  .succes-container {
    width: 85% !important;
  }
}

@media screen and (max-width: 679px) {
  .succes-container {
    width: 90% !important;
  }
}

@media screen and (max-width: 592px) {
  .cancel-img {
    width: 170px !important;
    height: 170px !important;
    margin-top: -100px;
  }
}

@media screen and (max-width: 397px) {
  .cancel-img {
    width: 150px !important;
    height: 150px !important;
  }
  .success-user-details > div {
    display: flex !important;
    flex-direction: column !important;
  }
  .success-text {
    font-size: 16px !important;
  }
}
