@media screen and (max-width: 480px) {
  .shrinkFooter .footer {
    width: 300px !important;
  }
}

@media screen and (max-width: 390px) {
  .shrinkFooter .footer {
    width: 300px !important;
    margin-left: -50px !important;
    word-break: break-word !important;
  }
}

@media screen and (max-width: 336px) {
  .shrinkFooter .footer {
    width: 300px !important;
    margin-left: -65px !important;
    word-break: break-word !important;
  }
}
