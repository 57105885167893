.right {
  align-self: flex-end;
}

.container-right {
  display: flex !important;
  justify-content: flex-end !important;
}

.stripe-margin {
  margin-right: 26px;
}

.wire-margin {
  margin-right: 25px;
}

.n-stripe-margin {
  margin-right: 50px;
}

.amount {
  display: flex !important;
  align-items: center !important;
}

.amount > input {
  width: 93% !important;
  border-radius: 10px 0px 0px 10px !important;
}

.logoUp {
  position: relative;
  top: -150px;
  z-index: 100;
}

.scrollable .table-responsive {
  overflow: auto !important;
}

.amount > span {
  width: 7% !important;
  border: 1px solid #ebebeb !important;
  text-align: center !important;
  border-radius: 0px 10px 10px 0px !important;
  color: #395cbe !important;
  padding: 7px 0px !important;
  cursor: pointer !important;
}

.privateKey {
  color: #82c91e;
  position: relative;
  margin-left: 30px;
  cursor: pointer !important;
  /* width: 50px !important;
    height: 50px !important; */
}

.pdfHeader {
  display: flex !important;
  justify-content: flex-start !important;
  align-items: center !important;
  background-image: url(../../assets/img/PdfBackground.png);
  background-size: 100% 100% !important;
  height: fit-content !important;
  background-repeat: no-repeat !important;
  padding: 10px !important;
  height: 160px !important;
}

.Otp-component input {
  padding: 10px 15px !important;
  text-align: center !important;
  margin-right: 10px !important;
  margin-left: 10px !important;
  border: 1px solid #ebebeb !important;
  border-bottom: 2px solid #395cbe !important;
  border-radius: 5px !important;
  width: 40px !important;
  height: 40px !important;
}

.emailError {
  color: red !important;
}

.paymentIcons {
  width: 90px !important;
  height: 90px !important;
  cursor: pointer;
  padding: 5px !important;
  /* box-shadow: 1px 1px px rgba(0, 0, 0, 0.25); */
}

.paymentIcons1 {
  width: 96px !important;
  height: 98px !important;
  cursor: pointer;
  padding: 4px !important;
  /* position: relative;
    left: -132px */
  /* box-shadow: 1px 1px px rgba(0, 0, 0, 0.25); */
}

.paymentIcons2 {
  position: relative;
  top: 4px;
  border-radius: 10px;
  background: #0f2862;
  height: 90px;
  width: 96px;
  cursor: pointer;
  padding: 4px;
}

@media screen and (max-width: 1300px) {
  .payHeading {
    font-size: 25px !important;
  }
}

@media screen and (max-width: 946px) {
  /* .payment-method-container {
        display: flex !important;
        flex-direction: column;
        justify-content: space-between !important;
        align-items: center;
    } */
  .payment-method-container > div {
    margin-top: 10px !important;
  }
}

@media screen and (max-width: 927px) {
  .payHeading {
    font-size: 24px !important;
  }
}

@media screen and (max-width: 576px) {
  .paymentIcons {
    width: 70px !important;
    height: 70px !important;
    cursor: pointer;
    padding: 5px !important;
  }
  .paymentIcons1 {
    position: relative;
    width: 79px !important;
    height: 79px !important;
    cursor: pointer;
    padding: 4px !important;
  }
  .paymentIcons2 {
    width: 70px !important;
    height: 70px !important;
    cursor: pointer;
    padding: 4px !important;
  }
}

@media screen and (max-width: 474px) {
  .stripe-margin {
    margin-right: 50px;
  }
  .payment-method-container {
    display: flex !important;
    flex-direction: column;
    justify-content: center !important;
    align-items: center;
  }
  .btc-margin {
    margin-right: 50px;
    left: -5px;
  }
  .dec-margin {
    position: relative;
    left: -25px;
  }
  .wire-margin {
    margin-right: 8px;
  }
  .n-stripe-margin {
    margin-right: 20px;
  }
  .paymentIcons {
    width: 90px !important;
    height: 90px !important;
    cursor: pointer;
    padding: 5px !important;
  }
  .paymentIcons1 {
    width: 96px !important;
    width: 96px !important;
    height: 98px !important;
    cursor: pointer;
    padding: 4px !important;
  }
  .paymentIcons2 {
    position: relative;
    top: 4px;
    left: -5px;
    border-radius: 10px;
    background: #0f2862;
    width: 90px !important;
    height: 90px !important;
    cursor: pointer;
    padding: 4px;
  }
}

@media screen and (max-width: 469px) {
  .stripe-margin {
    margin-right: 50px;
  }
  .payHeading {
    font-size: 23px !important;
  }
}

@media screen and (max-width: 400px) {
  .paymentIcons {
    width: 70px !important;
    height: 70px !important;
    cursor: pointer;
    padding: 5px !important;
  }
  .paymentIcons1 {
    width: 75px !important;
    height: 75px !important;
    cursor: pointer;
    padding: 4px !important;
  }
  .paymentIcons2 {
    position: relative;
    width: 70px !important;
    height: 65px !important;
    cursor: pointer;
    padding: 1px !important;
  }
}

@media screen and (max-width: 374px) {
  .n-btc-margin {
    position: relative;
    left: -10px;
  }
  .content-left {
    position: relative;
    left: -10px;
  }
}

.filter-box {
  background: var(--background-color-three);
  padding: 20px !important;
  border-radius: 10px;
  box-shadow: 0px 0px 16px rgb(0 0 0 / 22%);
}

.payment-method-container {
  display: flex !important;
  justify-content: center !important;
  align-items: center;
}
