.signin-info-bottom-link {
  text-align: end !important;
  color: white !important;
  font-size: 18px !important;
}

.pull-right {
  float: right;
}

.signuppage-info-bottom-link {
  color: white !important;
  font-size: 18px !important;
}
.signinpt {
  padding-top: 3.5rem !important;
}

.min-width-45 {
  min-width: 45% !important;
}
.login-container {
  margin-top: 40px;
}

@media screen and (min-width: 180px) and (max-width: 961px) {
  .signuppage-info-bottom-link {
    width: 100%;
    text-align: center;
  }
  .signin-info-bottom-link {
    text-align: center !important;
  }
  .width-100 {
    width: 100% !important;
  }
}
