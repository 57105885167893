@font-face {
  font-family: "electronicFont";
  src: url("../../assets/fonts/DS-DIGIT.TTF");
  font-weight: normal;
  font-style: normal;
}

@keyframes card-gloss-moving-1 {
  0% {
    left: -100%;
  }
  30% {
    left: 100%;
  }
  100% {
    left: 100%;
  }
}

.dashboard-box strong {
  font-weight: bolder;
}

.dashboard-box .row {
  justify-content: space-between;
}

.dashboard-box .card-container {
  position: relative;
  z-index: 0;
  overflow: hidden;
}

.dashboard-box .card-container::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  background-image: linear-gradient(
    to right,
    transparent,
    rgba(255, 255, 255, 0.5),
    transparent
  );
  left: -100%;
  animation: card-gloss-moving-1 5s linear 5s;
}

.dashboard-box .dashboard-card {
  height: 100%;
  border-radius: 0 !important;
  background-color: transparent;
}

.dashboard-box .CircularProgressbar {
  width: 100% !important;
  height: 100% !important;
}

.dashboard-box .circle-container {
  --circle-width: 120px;
  width: var(--circle-width);
  height: var(--circle-width);
  margin: 30px auto;
}

.dashboard-box .sub-circle > div > div {
  width: var(--circle-width) !important;
}

.dashboard-box .gloss-day {
  background-image: linear-gradient(
    95deg,
    rgba(225, 224, 224, 0.098),
    rgb(253, 253, 253),
    rgba(207, 207, 207, 0.3)
  );
}

.dashboard-box .gloss-night {
  background-image: linear-gradient(
    95deg,
    rgba(127, 129, 142, 0.1),
    rgba(255, 255, 255, 0.2),
    rgba(127, 129, 142, 0.25)
  );
}

.dashboard-box .time-horizon-selector {
  border-radius: 5px;
  padding: 5px 10px;
}

.dashboard-box .time-horizon-selector:hover {
  cursor: pointer;
}

.dashboard-box .rate-of-return-toolbar {
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 20px;
  margin-top: -20px;
  margin-left: 30px;
}

.dashboard-box .rate-of-return-period {
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 20px;
  margin-top: 5px;
}

.dashboard-box .rate-of-return-toolbar .form-control {
  display: inline-block;
  width: 100px;
  padding-top: 4px;
  padding-bottom: 4px;
}

.dashboard-box .rate-of-return-toolbar .custom-tooltip-coin {
  width: 30px;
}

.dashboard-box .earnings-currency-select {
  position: absolute;
  top: 63px;
  right: 24px;
  z-index: 9999;
}

.card-container {
  box-shadow: rgba(0, 0, 0, 0.1) 0 4px 6px -1px,
    rgba(0, 0, 0, 0.06) 0 2px 4px -1px;
}

.r-1 .card-container {
  height: 100%;
}

.c-flex {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.r-1 .c-1 .card-container::before {
  animation-delay: 5s;
}

.r-1 .c-2 .card-container::before {
  animation-delay: 5.8s;
}

.r-1 .c-3 .card-container::before {
  animation-delay: 6.6s;
}

.r-2 .c-1 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.r-2 .c-1 > div:nth-child(2) {
  flex-grow: 1;
}

.electronic-text {
  font-family: "electronicFont";
  font-size: 30px;
}

.text-small {
  font-size: 14px;
}

.text-50 {
  font-size: 50px;
}

.text-40 {
  font-size: 40px;
}

.dashboard-text-color {
  color: var(--text-color);
}

.text-black {
  color: black;
}

.secondary-data {
  display: inline-block;
  min-width: 100px;
}

.line-separator {
  margin: 10px 0;
  height: 3px;
  background-image: linear-gradient(to right, #e2e2e2 50%, transparent);
}

.worker-badge-container {
  display: inline-block;
}

.worker-badge-row {
  display: flex;
  padding: 10px 0;
}

.worker-badge-row span + span {
  margin-left: 15px;
  font-weight: bold;
}

.worker-badge {
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 4px;
}

.worker-badge-active {
  background-color: #efaafa;
}

.worker-badge-offline {
  background-color: #97faff;
}

.worker-badge-inactive {
  background-color: #efaafa;
}

.worker-badge-repairing {
  background-color: orange;
}

.text-semi-bold {
  color: var(--text-color-six);
  font-weight: 400;
}

@media (max-width: 991px) {
  .rate-of-return-container {
    margin-bottom: 30px;
  }
}
