.earningtableHeading {
  display: flex !important;
  justify-content: space-between !important;
  align-content: center !important;
}

.separator hr {
  background-color: var(--text-color-five) !important;
}

@media screen and (max-width: 451px) {
  .pagination {
    margin-top: -30px !important;
  }
  .containEarning {
    display: flex !important;
    flex-direction: column;
  }
  .title-horizonContainer {
    display: flex !important;
    flex-direction: column;
  }
  .earningtableHeading {
    display: flex !important;
    flex-direction: column;
    align-items: flex-start !important;
  }
}

@media screen and (max-width: 488px) {
  .containEarning {
    display: flex !important;
    flex-direction: column;
  }
  .btcDropDown {
    margin-top: 15px;
    margin-left: -10px;
  }
  .horizon {
    position: relative;
    left: -15px !important;
    margin-top: 15px !important;
    /* margin-left: -20px; */
  }
  .title-horizonContainer {
    display: flex !important;
    flex-direction: column;
  }
}

@media screen and (min-width: 451px) {
  .pagination {
    margin-top: 0px !important;
  }
}

.containEarning {
  display: flex !important;
  justify-content: space-between !important;
}

.title-horizonContainer {
  display: flex !important;
  justify-content: space-between !important;
}

.separator {
  margin-top: 40px !important;
}

.separator span {
  position: relative !important;
  top: -26px !important;
  font-weight: 900;
  background: var(--background-color-three) !important;
}

.onboarding-content {
  min-width: 550px;
}

.btn-primary {
  color: white !important;
}

.separator {
  margin-top: 40px !important;
  text-align: center !important;
}

.separator span {
  position: relative !important;
  top: -26px !important;
  font-weight: 900;
  background: var(--background-color-three) !important;
  align-self: center !important;
}

.custom-table-th {
  font-size: 12px !important;
}

.switch-actual-live {
  display: flex;
  justify-content: center;
}

.actual-live-switch {
  margin-left: 20px;
}
